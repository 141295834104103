
'use strict';

export const $clientInterpreterCourtDistanceService  = function (ClientInterpreterCourtDistance) {
        return {
            list: function (client, filter) {
                return ClientInterpreterCourtDistance.list({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            syncDistance: function (client, interpreter, requiredDistances) {
                return ClientInterpreterCourtDistance.syncDistance({
                    id: client._id || client,
                    interpreter, requiredDistances
                }).$promise;
            },
            interpreterCourtDistance: function (client, filter) {
                return ClientInterpreterCourtDistance.interpreterCourtDistance({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            interpreterCourtDistanceByCourt: function (client, filter) {
                return ClientInterpreterCourtDistance.interpreterCourtDistanceByCourt({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            courtInterpreterDistance: function (client, filter) {
                return ClientInterpreterCourtDistance.courtInterpreterDistance({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            syncDistanceByCourtCode: function(client, courtCode) {
                return ClientInterpreterCourtDistance.syncDistanceByCourtCode({
                    id: client._id || client,
                    court: courtCode
                }).$promise;
            },
            syncSingleCourtDistance : function(client, data) {
                return ClientInterpreterCourtDistance.interpreterSingleCourtDistanceSync({
                    id: client._id || client,
                    court_code: data.court_code, 
                    court_address: data.court_address,
                    interpreter_address: data.interpreter_address,
                    vendorid: data.vendorid,
                }).$promise;
            },
            pendingSyncList : function(client) {
                return ClientInterpreterCourtDistance.pendingSyncList({
                    id: client._id || client
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$clientInterpreterCourtDistanceService.$inject = ['ClientInterpreterCourtDistance'];
