import './user.scss';
'use strict';


export const formUserDirective = function ($rootScope, $q, $auth, $clientSwitcher, $clientUser,
    $clientRegion, $clientManager, $clientOffice, $clientCampaign, $client, $clientUserSetting, $timezones,
    $clientEmployee, $activeClientSettings, TASK_SCHEMA_TYPE_SURVEY, $clientRoles, $passwordStrengthValidator, $layoutItems, $removeAbility, $states, $clientLanguages, ngToast) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/user.html',
      scope: {
        user: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset',
        requirePassword: '=formRequirePassword',
        isServicePortal: '=isServicePortal'
      },
      link: function (scope, el, attrs) {
        var filterTimezones = ['Asia', 'Africa', 'Brazil', 'Australia', 'Europe', 'Indian', 'Pacific'];
        scope.client;
        scope.allValue = 'all';
        scope.itemDelimiter = ', ';
        scope.loadingInfo = false;
        scope.loading = true;
        scope.isInterpreterRoleSelected = false;
        scope.model = {
          defaultOffice: null,
          dashboardItems: null,
          // allowJudge: scope.user.allowJudge && scope.user.allowJudge.length ? scope.user.allowJudge : []
        }
        if (scope.user && scope.user._id && !scope.user.allowLegalAssitant) {
          scope.user.allowLegalAssitant = null
        }
        scope.user.roles = scope.user.roles && scope.user.roles.length > 0 ? scope.user.roles[0] : scope.user.roles;
        // Set as default owner account role in accessLevel for card #690 in SOSI1-DEV
        scope.user.accessLevel = 1;
        scope.authUser = $auth.getCurrentUser();
        scope.dashboardTabsItems = _.sortBy($layoutItems(scope.authUser.permissions), 'position');
        scope.defaultTabs = {};
        scope.childArr = {};
        scope.activeLanguages = [];
        scope.dashboardTabsItems.map(obj => {
          //set default first selected
          if (scope.user.defaultDashboardTabs) {
            scope.defaultTabs = scope.user.defaultDashboardTabs
            // scope.defaultTabs[obj.value] = scope.user.defaultDashboardTabs[obj.value] || '';
          }
          scope.childArr[obj.value] = (obj.child && obj.child.length) ? obj.child : [];
        });
        scope.oldUser = JSON.parse(JSON.stringify(scope.user));
        scope.isEmailUpdate = false;
        scope.isInternalIdUpdate = false;
        // scope.timezones = moment.tz.names()
        //   .filter(function(timezone) {
        //     return _.every(filterTimezones, function(timezoneToIgnore) {
        //       var timezoneRegex = new RegExp(timezoneToIgnore, 'i');

        //       return !timezoneRegex.test(timezone);
        //     });
        //   })
        //   .map(function(timezone) {
        //     return {
        //       label: timezone,
        //       value: timezone
        //     };
        //   });
        // scope.timezones = [
        //   { label: "Atlantic Standard/Daylight Time", value: "AST4ADT" },
        //   { label: "Eastern Standard/DaylightTime", value: "EST5EDT" },
        //   { label: "Central Standard/Daylight Time", value: "CST6CDT" },
        //   { label: "Mountain Standard/Daylight Time", value: "MST7MDT" },
        //   { label: "Pacific Standard/Daylight Time", value: "PST8PDT" },
        //   { label: "Alaska Standard/Daylight Time", value: "AKST9AKDT" },
        //   { label: "Hawaii-Aleutian Standard/Daylight Time", value: "HAST10HADT" },
        //   { label: "Eastern Standard Time", value: "EST" }]
        //sort them alphabetically
        scope.timezones = _.sortBy($timezones, 'label');
        scope.states = _.sortBy($states, 'label');
        scope.clientFeatures = [];
        scope.clientAbilityItems = [];
        scope.enabledAbilityItems = [];
        scope.callAbilityItems = [];
        scope.associatedEmployee = null;
        scope.userRoleTabs = [];
        scope.legalAssitantUsers= [];
        scope.clientRoles = [{
          label: 'Owner',
          value: 1
        }, {
          label: 'Operator',
          value: 2
        }, {
          label: 'Limited',
          value: 3
        }];

        // scope.defaultTabs = [
        //   [0, 'Some Default Tab']
        // ];

        // scope.$watch('user.clientAbilities', function (nv) {

        //   var enabled = angular.isArray(nv) ? nv : [];

        //   scope.enabledAbilityItems = scope.clientAbilityItems
        //     // .filter(function (item) {
        //     //   return enabled.indexOf(item.value) > -1;
        //     // });
        // });

        scope.callAbilitySelectedStr = 'Call Abilities: ';
        scope.callAbilityNoSelectionStr = 'No Abilities Selected';
        scope.callAbilityAllLabel = 'All Call Abilities';

        scope.settingsKey = 'client.settings';
        scope.tasksKey = 'client.tasks';
        scope.satisfactionKey = 'client.satisfaction';
        scope.timeProductionReportingKey = 'client.TimeProductionReporting';
        scope.trainingKey = 'client.training';
        scope.reportBucketsKey = 'client.Reports';
        scope.officeInformationKey = 'client.officeInformation';
        scope.officeOperationsKey = 'client.officeOperations';
        scope.remoteWork = 'client.remoteWork';
        scope.hasAbility = $clientSwitcher.hasAbility.bind($clientSwitcher);
        scope.isAdminUser = $auth.isAdmin.bind($auth);

        function isThingCustomized(set, allValue) {
          allValue = allValue || scope.allValue;
          if (!scope.user || !scope.user[set]) return false;
          return (!!scope.user[set].length && scope.user[set].indexOf(allValue) === -1) && scope.user[set] !== allValue;
        }

        var satisfactionListeners = [];

        function reloadSatisfactionItems() {

          if (satisfactionListeners.length > 0) { // kill any previous watchers
            satisfactionListeners.forEach(function (killWatcher, index) { killWatcher(); });
            satisfactionListeners.splice(0, satisfactionListeners.length);
          }

          scope.clientSatisfactionBuckets = null;
          scope.clientSatisfactionOptions = $clientSwitcher.getFeatureOptions(scope.satisfactionKey);

          if (scope.clientSatisfactionOptions && scope.clientSatisfactionOptions.clientBuckets) {
            scope.user[scope.satisfactionKey] = scope.user[scope.satisfactionKey] || {};
            scope.clientSatisfactionBuckets = scope.clientSatisfactionOptions.clientBuckets;
            scope.clientSatisfactionBuckets.forEach(function (bucket) {
              var
                viewWatcher = 'user["' + scope.satisfactionKey + '"].view' + bucket.typeCode,
                alertSetterKey = 'alert' + bucket.typeCode, resolveSetterKey = 'resolve' + bucket.typeCode;

              satisfactionListeners.push(scope.$watch(viewWatcher, function (val) {
                if (!val) {
                  if (bucket.alertable) {
                    scope.user[scope.satisfactionKey][alertSetterKey] = false;
                  }
                  else {
                    delete scope.user[scope.satisfactionKey][alertSetterKey];
                  }

                  if (bucket.resolvable) {
                    scope.user[scope.satisfactionKey][resolveSetterKey] = false;
                  }
                  else {
                    delete scope.user[scope.satisfactionKey][resolveSetterKey];
                  }
                }
              }));
            });
          }
        }
        scope.setDefaultTabs = function () {
          scope.userRoleTabs = [];
          scope.isServicePortalRoleSelected = false;
          if (!scope.user.roles) return;
          let role = scope.allRoles.filter(item => item.value == scope.user.roles);
          if (role && role.length && role[0].label == 'Service Portal') {
            scope.userRoleTabs = scope.enabledAbilityItems.filter(item => item.isServiceUserOption)
            scope.isServicePortalRoleSelected = true;
          } else {
            scope.userRoleTabs = scope.enabledAbilityItems.filter(item => !item.isServiceUserOption)
          }
          scope.checkInterpreterRole();
        }

        function loadAbilities(cli) {
          var
            accessLevel = scope.user.accessLevel,
            tmpfeatures = [];

          const currentCli = $clientSwitcher.getCurrentClient();
          $client.clientAbilities(currentCli)
            .then(data => {
              delete data.data.options['client.strings'];

              const hasAccessLevel = !isNaN(accessLevel);

              const feat = data.data;
              // Remove not use ability
              $removeAbility.forEach((ability) => {
                delete data.data.options[ability];
              })

              scope.clientAbilityItems = Object.keys(feat.options || {})
                .filter(function (o) {
                  var options = feat.options[o];
                  return options.actAsTab && options.allowTab && (!hasAccessLevel || options.requireLevel >= accessLevel);
                })
                .sort(function (a, b) {
                  var
                    aSort = feat.options[a].tabSort,
                    bSort = feat.options[b].tabSort;
                  return aSort - bSort;
                })
                .map(function (o) {
                  var options = feat.options[o];
                  return {
                    id: o,
                    title: options.tabTitle,
                    state: options.tabState,
                    icon: options.tabIcon,
                    disabled: !!options.tabDisabled,
                    permission: options.tabPermission ? options.tabPermission : 'NONE',
                    isServiceUserOption: options.tabData && options.tabData.isServicePortal ? true : false
                  };
                })
                .filter(y => !y.disabled)
                .map(x => {
                  tmpfeatures.push(x.id);
                  return {
                    value: x.id,
                    label: x.title,
                    permission: x.permission,
                    disabled: x.disabled,
                    isServiceUserOption: x.isServiceUserOption
                  }
                });


              if (scope.user && scope.user.clientAbilities) {
                scope.user.clientAbilities
                  .filter(function (ability) {
                    return tmpfeatures.indexOf(ability) === -1;
                  })
                  .forEach(function (rmAbility) {
                    var rmIndex = scope.user.clientAbilities.indexOf(rmAbility);
                    if (rmIndex > -1) scope.user.clientAbilities.splice(rmIndex, 1);

                    // remove any settings for this ability:
                    // console.log('removing any ability settings:', rmAbility, scope.user[rmAbility]);
                    // delete scope.user[rmAbility];
                  });
              }

              scope.callAbilityItems = $clientSwitcher.getFeaturePhone(cli, accessLevel)
                .map(function (feature) {
                  tmpfeatures.push(feature.id);
                  return {
                    value: feature.id,
                    label: feature.title
                  };
                });

              // filter any user callAbilities that aren't in this list:
              if (scope.user && scope.user.callAbilities) {
                scope.user.callAbilities
                  .filter(function (ability) {
                    return tmpfeatures.indexOf(ability) === -1;
                  })
                  .forEach(function (rmAbility) {
                    var rmIndex = scope.user.callAbilities.indexOf(rmAbility);
                    if (rmIndex > -1) scope.user.callAbilities.splice(rmIndex, 1);

                    // remove any settings for this ability:
                    delete scope.user[rmAbility];
                  });
              }

              if (currentCli) {
                $clientUser.getUserPermissions(currentCli)
                  .then(data => {
                    scope.enabledAbilityItems = [];
                    if (data.permissions && data.permissions.length) {
                      _.each(scope.clientAbilityItems, function (tab) {
                        if (!tab.disabled && data.permissions.includes(tab.permission)) {
                          // Remove not use ability
                          if ($removeAbility.indexOf(tab.id) <= -1) {
                            scope.enabledAbilityItems.push(tab);
                          }
                        }
                      });
                    }
                    scope.setDefaultTabs();
                  })
              }
            });

          // scope.clientAbilityItems = $clientSwitcher.getFeatureTabs(cli, accessLevel)
          //   .map(function (feature) {
          //     tmpfeatures.push(feature.id);
          //     return {
          //       value:  feature.id,
          //       label: feature.title
          //     };
          //   });


          reloadSatisfactionItems();

          // filter any user clientAbilities that aren't in this list:
          // if(scope.user && scope.user.clientAbilities) {
          //   scope.user.clientAbilities
          //     .filter(function (ability) {
          //       return tmpfeatures.indexOf(ability) === -1;
          //     })
          //     .forEach(function (rmAbility) {
          //       var rmIndex = scope.user.clientAbilities.indexOf(rmAbility);
          //       if(rmIndex > -1) scope.user.clientAbilities.splice(rmIndex, 1);

          //       remove any settings for this ability:
          //       console.log('removing any ability settings:', rmAbility, scope.user[rmAbility]);
          //       delete scope.user[rmAbility];
          //     });
          // }

          // scope.callAbilityItems = $clientSwitcher.getFeaturePhone(cli, accessLevel)
          //   .map(function (feature) {
          //     tmpfeatures.push(feature.id);
          //     return {
          //       value:  feature.id,
          //       label: feature.title
          //     };
          //   });

          // // filter any user callAbilities that aren't in this list:
          // if(scope.user && scope.user.callAbilities) {
          //   scope.user.callAbilities
          //     .filter(function (ability) {
          //       return tmpfeatures.indexOf(ability) === -1;
          //     })
          //     .forEach(function (rmAbility) {
          //       var rmIndex = scope.user.callAbilities.indexOf(rmAbility);
          //       if(rmIndex > -1) scope.user.callAbilities.splice(rmIndex, 1);

          //       // remove any settings for this ability:
          //       delete scope.user[rmAbility];
          //     });
          // }

        }

        function loadRegions(cli) {
          return $client.getRegions(cli)
            .then(function (regions) { return scope.regionItems = regions; });
        }

        function loadOffices(cli) {
          return $client.getOffices(cli)
            .then(function (offices) { return scope.officeItems = offices; });
          // return $clientOffice.getActiveOfficeList(cli)
          //       .then((res) => {
          //           if (res.status) {
          //             return scope.officeItems = res.result;
          //           }
          //           return []
          //       })
        }

        function loadBuckets(cli) {
          return $client.getBuckets(cli)
            .then(function (buckets) { return scope.bucketItems = buckets; });
        }

        function loadManagers(cli) {
          return $client.getManagers(cli, true)
            .then(function (managers) { return scope.managerItems = managers; });
        }

        function loadCampaigns(cli) {
          return $client.getCampaigns(cli, true)
            .then(function (campaigns) { return scope.campaignItems = campaigns; });
        }

        function loadPossibleParentUsers(cli) {
          return $clientUser.possibleParents(cli, scope.user.userId) // if create user, user._id is undefined!
            .then(function (parents) {

              parents.push({ _id: null, name: '---' })

              return scope.possibleParents = parents.map(function (parent) {
                return {
                  label: parent.name,
                  value: parent._id
                };
              });
            });
        }

        function loadRoles(cli) {
          return $clientRoles.getRoles(cli)
            .then(({ data }) => {
              return scope.allRoles = data.map(({ name, _id, code }) => ({ label: name, value: _id, code }));
            })
        }

        function loadClientInfo(cli) {
          if (scope.loadingInfo || !cli) return; // ignore any parallel requests
          scope.loadingInfo = true;
          scope.model.defaultOffice = scope.user.defaultOffice && scope.user.defaultOffice.id ? scope.user.defaultOffice.id : null

          return $q.all([
            // loadAbilities(cli),
            loadRegions(cli),
            loadOffices(cli),
            loadBuckets(cli),
            // loadManagers(cli),
            // loadCampaigns(cli),
            loadPossibleParentUsers(cli),
            loadRoles(cli),
            getLanguages(cli)
          ])
            .then(() => {
              // setTimeout(() => {
              //   scope.setDefaultTabs();
              //   scope.$apply();
              // }, 500)
            })
            .catch(function (err) {
              scope.loadingError = err;
              return err;
            })
            .finally(function () {
              scope.loadingInfo = false;
              reloadAssociationOptions();
              reloadAssociationSelection();
              if (scope.user.userId) { // dont try to fetch assocaited employee when creating new user
                loadAssocaitedEmployee(cli);
                loadAssocaitedShareTreeEmployee(cli);
              }
            });
        }

        var loadAssocaitedEmployee = function (cli) {
          return $clientEmployee.getAssociatedEmployee(cli, scope.user.userId)
            .then(function (res) {
              if (res.status) {
                scope.associatedEmployee = res.data;
              }
              else {
                console.error(res.reason);
              }
            })
            .catch(function (err) {
              console.error(err);
            });
        };

        var loadAssocaitedShareTreeEmployee = function (cli) {
          var shareTree = 'true';
          return $clientEmployee.getAssociatedEmployee(cli, scope.user.userId, shareTree)
            .then(function (res) {
              if (res.status) {
                if (res.data) {
                  scope.associatedShareTreeEmployee = res.data;
                } else {
                  scope.associatedShareTreeEmployee = false;
                }
              }
              else {
                console.error(res.reason);
              }
            })
            .catch(function (err) {
              console.error(err);
            });
        };

        var killCsLoad, killCsSwitch;

        $clientSwitcher.whenLoaded(function (all, current) {
          scope.client = current;
          loadClientInfo(current);

          killCsLoad = $rootScope.$on('client-switcher-load', function (evt, all, current) { // listen for reloads too
            loadClientInfo(current);
          });
          killCsSwitch = $rootScope.$on('client-switcher-switch', function (evt, client) {
            loadClientInfo(client);
          });
        });

        scope.$watch('user.accessLevel', function (nV) {
          loadAbilities();
        });

        scope.$on('$destroy', function () {
          killCsLoad();
          killCsSwitch();
        });

        scope.clientStrings = function () { return $clientSwitcher.getFeatureOptions('client.strings'); };

        function canShowOffice(office) {
          if (!office.region || !scope.user.allowRegions) {
            return false;
          }

          if (scope.user.allowRegions === scope.allValue) {
            return true;
          }

          return scope.user.allowRegions.indexOf(office.region._id) > -1;
        }

        function canShowManageLocations(office) {
          if (!office.region) {
            return false;
          }
          if (scope.user.allowRegions === scope.allValue) {
            return true;
          }

          return scope.user.allowRegions.indexOf(office.region._id) > -1;
        }

        function canShowManager(manager) {
          if (!scope.hasCustomOffices() || !manager.offices) return false;

          return !manager.offices.every(function (v) {
            return scope.user.allowOffices.indexOf(v) === -1;
          });
        }

        function canShowCampaign(campaign) {
          if (!scope.hasCustomRegions() || !scope.hasCustomOffices() || !campaign.offices) return false;

          var
            allowsRegion = scope.user.allowRegions.indexOf(campaign.region) > -1,
            allowsOffice = allowsRegion && !campaign.offices.every(function (v) {
              return scope.user.allowOffices.indexOf(v) === -1;
            });

          return allowsRegion && allowsOffice;
        }

        function checkAssociations() {
          var allValue = scope.allValue;
          if (!scope.user) return;

          if (scope.regionItems === undefined ||
            scope.officeItems === undefined ||
            scope.managerItems === undefined ||
            scope.campaignItems === undefined) {
            return
          }

          // if(!scope.hasCustomRegions()) {
          //   scope.user.allowOffices = allValue;
          // }
          if (!scope.hasCustomOffices()) {
            scope.user.allowManagers = allValue;
          }
          if (!scope.hasCustomOffices()) {
            scope.user.allowCampaigns = allValue;
          }

          reloadAssociationSelection();
        }

        function reloadAssociationOptions() {

          scope.regionItemsFiltered = (scope.regionItems || [])
            .map(function (item) {
              return { value: item._id, label: item.name };
            });

          scope.officeItemsFiltered = (scope.officeItems || [])
            .map(function (item) {
              return {
                value: item._id,
                label: item.name,
                internalId: item.internalId,
                // disableIf: ...
                showIf: function () { return canShowOffice(item); }
              };
            });

          scope.manageLocationItemsFiltered = (scope.officeItems || [])
            .map(function (item) {
              return {
                value: item._id,
                label: item.name,
                // disableIf: ...
                showIf: function () { return canShowManageLocations(item); }
              };
            });

          scope.bucketItemsFiltered = (scope.bucketItems || [])
            .map(function (item) {
              return {
                value: item._id,
                label: item.name
              };
            });

          scope.managerItemsFiltered = (scope.managerItems || [])
            .map(function (item) {
              return {
                value: item._id,
                label: item.name,
                showIf: function () { return canShowManager(item); }
              };
            });

          scope.campaignItemsFiltered = (scope.campaignItems || [])
            .map(function (item) {
              return {
                value: item._id,
                label: item.name,
                showIf: function () { return canShowCampaign(item); }
              };
            });
        }

        function reloadAssociationSelection() {

          var
            availRegions = (scope.regionItems || [])
              .map(function (itm) { return itm._id; }),
            availOffices = (scope.officeItems || [])
              .filter(canShowOffice)
              .map(function (itm) { return itm._id; }),
            availManagers = (scope.managerItems || [])
              .filter(canShowManager)
              .map(function (itm) { return itm._id; }),
            availCampaigns = (scope.campaignItems || [])
              .filter(canShowCampaign)
              .map(function (itm) { return itm._id; });

          //
          // clean-up any previous selections that arent in filtered view.
          //

          if (scope.hasCustomOffices()) {
            scope.user.allowOffices
              .filter(function (allowOffice) {
                return availOffices.indexOf(allowOffice) === -1;
              })
              .forEach(function (rmOffice) {
                var rmIndex = scope.user.allowOffices.indexOf(rmOffice);
                if (rmIndex > -1) scope.user.allowOffices.splice(rmIndex, 1);
              });
          }

          if (scope.hasCustomManagers()) {
            scope.user.allowManagers
              .filter(function (allowManager) {
                return availManagers.indexOf(allowManager) === -1;
              })
              .forEach(function (rmManager) {
                var rmIndex = scope.user.allowManagers.indexOf(rmManager);
                if (rmIndex > -1) scope.user.allowManagers.splice(rmIndex, 1);
              });
          }

          if (scope.hasCustomCampaigns()) {
            scope.user.allowCampaigns
              .filter(function (allowCampaign) {
                return availCampaigns.indexOf(allowCampaign) === -1;
              })
              .forEach(function (rmCampaign) {
                var rmIndex = scope.user.allowCampaigns.indexOf(rmCampaign);
                if (rmIndex > -1) scope.user.allowCampaigns.splice(rmIndex, 1);
              });
          }
        }

        scope.userHasAbility = function (abilityId) {
          if (!scope.clientAbilityItems || !scope.user) return false;

          var
            hasAbility = !scope.clientAbilityItems.every(function (item) {
              return item.id === abilityId;
            });

          if (hasAbility && !!scope.user.clientAbilities) { // see if ability is enabled:
            return scope.user.clientAbilities.indexOf(abilityId) !== -1;
          }

          return false;
        };

        scope.hasAbilitySettings = function () {
          return scope.userHasAbility('client.settings');
        };

        scope.hasAbilitySatisfaction = function () {
          return scope.userHasAbility('client.satisfaction');
        };

        scope.hasAbilityTasks = function () {
          return scope.userHasAbility('client.tasks');
        };

        scope.hasAbilityTimeProductionReporting = function () {
          return scope.userHasAbility(scope.timeProductionReportingKey);
        };

        scope.hasCustomRegions = function () {
          return isThingCustomized('allowRegions');
        };

        scope.hasCustomOffices = function () {
          return isThingCustomized('allowOffices');
        };

        scope.hasCustomManagers = function () {
          return isThingCustomized('allowManagers');
        };

        scope.hasCustomCampaigns = function () {
          return isThingCustomized('allowCampaigns');
        };

        scope.$watch('user.allowRegions', checkAssociations);
        scope.$watch('user.allowOffices', checkAssociations);
        scope.$watch('user.allowManagers', checkAssociations);
        scope.$watch('user.allowCampaigns', checkAssociations);
        scope.$watch('user.manageOffices', checkAssociations);

        // var // user satisfied resets:
        // settingUserSat = 'user["'+scope.satisfactionKey+'"]';

        // [
        //   [settingUserSat + '.viewVerySatisfied',    'alertVerySatisfied'],
        //   [settingUserSat + '.viewSatisfied',        'alertSatisfied'],
        //   [settingUserSat + '.viewDissatisfied',     'alertDissatisfied'],
        //   [settingUserSat + '.viewVeryDissatisfied', 'alertVeryDissatisfied']
        // ].forEach(function (pair) {
        //   scope.$watch(pair[0], function (val) {
        //     if(val === 'deny') {
        //       scope[settingUserSat] = scope[settingUserSat] || {};
        //       scope.user[scope.satisfactionKey][pair[1]] = false;
        //     }
        //   });
        // });

        scope.cliAbilitySettings = function () {
          var clientStr = (scope.clientStrings() || {}).clientPCase;
          return {
            selectedStr: clientStr + ' Abilities: ',
            noSelection: 'No ' + clientStr + ' Abilities Selected',
            allLabel: 'All ' + clientStr + ' Abilities'
          };
        };

        scope.regionSettings = function () {
          var regionStr = (scope.clientStrings() || {}).regionsPCase;
          return {
            selectedStr: 'Selected ' + regionStr + ': ',
            noSelection: 'No ' + regionStr + ' Selected',
            allLabel: 'All ' + regionStr
          };
        };

        scope.bucketSettings = function () {
          var bucketStr = (scope.clientStrings() || {}).bucketsPCase;
          return {
            selectedStr: 'Selected ' + bucketStr + ': ',
            noSelection: 'No ' + bucketStr + ' Selected',
            allLabel: 'All ' + bucketStr
          };
        };

        scope.officeFilterComp = function (v, i) {
        };

        scope.officeSettings = function () {
          var officeStr = (scope.clientStrings() || {}).officesPCase;
          return {
            selectedStr: 'Selected ' + officeStr + ': ',
            noSelection: 'No ' + officeStr + ' Selected',
            allLabel: 'All ' + officeStr
          };
        };

        scope.managerSettings = function () {
          var managerStr = (scope.clientStrings() || {}).managersPCase;
          return {
            selectedStr: 'Selected ' + managerStr + ': ',
            noSelection: 'No ' + managerStr + ' Selected',
            allLabel: 'All ' + managerStr
          };
        };

        scope.campaignSettings = function () {
          var campaignStr = (scope.clientStrings() || {}).campaignsPCase;
          return {
            selectedStr: 'Selected ' + campaignStr + ': ',
            noSelection: 'No ' + campaignStr + ' Selected',
            allLabel: 'All ' + campaignStr
          };
        };

        function loadData(client) {
          scope.loading = true;
          scope.showOrgTab = false;
          if (scope.isServicePortal) {
            let userAddress = Object.values(scope.user.address), billingAddress = Object.values(scope.user.billingAddress);
            scope.user.sameAsContactAdd = userAddress.toString() == billingAddress.toString()
          }
          return $clientUserSetting.fetch(client, scope.user.userId)
            .then(function (settings) {
              scope.settings = settings.notifications;

              if (scope.user && scope.user.userId) {
                scope.loadUsersByRole('COURT_COORDINATOR')
                $q.all([
                  $clientUser.supportOrgNotifications(client, scope.user.userId),
                  $clientUser.getLinkedEmployees(client, scope.user.userId)
                ])
                  .then(function (res) {
                    var supportOrgNotificationsRes = res[0];
                    var linkedEmployeesRes = res[1];

                    scope.showOrgTab = supportOrgNotificationsRes.status && supportOrgNotificationsRes.data;

                    if (!scope.showOrgTab) {
                      delete scope.settings.tasksInMyOrg;
                    }

                    if (linkedEmployeesRes.status) {
                      scope.user.linkedEmployees = linkedEmployeesRes.data;
                    }
                    else {
                      console.error(res.reason);
                      scope.user.linkedEmployees = [];
                    }
                  });
              }
            })
            .finally(function () {
              scope.loading = false;
            });
        }

        scope.loadUsersByRole = function(roleCode) {
          if (scope.isJudgeLoading) return;
          scope.isJudgeLoading = true;
          $clientUser.getUsersByRoles($clientSwitcher.getCurrentClient(), [roleCode], true)
          .then((resultSet) => {
            scope.legalAssitantUsers = [];
            if (resultSet.status && resultSet.users && resultSet.users.length) {
              resultSet.users.map((obj) => {
                (obj.user).map(user => {
                  scope.legalAssitantUsers.push({
                    label: user.name,
                    value: user._id
                  })
                })
              })
              scope.legalAssitantUsers = _.sortBy(scope.legalAssitantUsers, ['label']);

            }
            scope.isJudgeLoading = false;
          })
          .catch((err) => {
            scope.isJudgeLoading = false;
            scope.error = err.message || err;
          })
        }

        scope.getDefaultNotifications = function (evt) {
          evt.preventDefault();

          $clientUserSetting.defaultSettings($clientSwitcher.getCurrentClient())
            .then(function (settings) {
              scope.settings = settings.notifications;
            });
        };

        scope.settings = null;

        scope.$watch(function (scope) { return JSON.stringify(scope.settings); }, function (nv) {
          scope.user.notificationSettings = scope.settings;
        });

        scope.locationTrackingChanged = function () {
          if (!scope.user[scope.tasksKey].enableLocationTracking) {
            scope.user[scope.tasksKey].autoStartLocationTracking = false;
          }
        };

        scope.timeWorkSettingsChanged = function () {
          if (scope.user[scope.tasksKey].allowEditTimeAllEmployees) {
            scope.user[scope.tasksKey].allowEditTimeOrgEmployees = false;
          }
          if (scope.user[scope.tasksKey].allowEditTimeorgEmployees) {
            scope.user[scope.tasksKey].allowEditTimeAllEmployees = false;
          }

          if (scope.user[scope.tasksKey].allowEditProductionAllEmployees) {
            scope.user[scope.tasksKey].allowEditProductionOrgEmployees = false;
          }
          if (scope.user[scope.tasksKey].allowEditProductionOrgEmployees) {
            scope.user[scope.tasksKey].allowEditProductionAllEmployees = false;
          }
        };

        scope.validateEmail = function () {
          if (!scope.user.email || scope.formUser.email.$invalid) {
            if (scope.formUser && scope.formUser.email && scope.formUser.email.$setValidity) {
              scope.formUser.email.$setValidity('unique', true);
            }
            return;
          }

          $clientUser.isEmailUnique(scope.client, scope.user.email, scope.user.userId)
            .then(function (res) {
              if (res.status && scope.formUser && scope.formUser.email && scope.formUser.email.$setValidity) {
                scope.formUser.email.$setValidity('unique', res.data);
              }
              else {
                if (scope.formUser && scope.formUser.email && scope.formUser.email.$setValidity) {
                  scope.formUser.email.$setValidity('unique', false);
                  // console.error(res.reason);
                }
              }
            });
        };

        scope.onEmailChange = function () {
          scope.isEmailUpdate = false;
          if (scope.oldUser && scope.oldUser.email && scope.oldUser.email !== scope.user.email) {
            scope.isEmailUpdate = true;
          }
        }
        loadData($clientSwitcher.getCurrentClient());

        $activeClientSettings(function (client, settings) {
          scope.supportedTabs = settings.supportedTabs || [];
          scope.user.supportedTabs = scope.user.supportedTabs || [];
          scope.supportedTabs = scope.supportedTabs
            .map(function (tab) {
              var tabLabelName = '';
              if (tab == 'buckets') tabLabelName = scope.clientStrings().homeBucketsPCase;
              else if (tab == 'organization') tabLabelName = scope.clientStrings().homeOrganizationPCase;
              else if (tab == 'calendar') tabLabelName = scope.clientStrings().homeCalendarPCase;
              else if (tab == 'reports') tabLabelName = scope.clientStrings().homeReportsPCase;
              else if (tab == 'requisitions') tabLabelName = scope.clientStrings().homeRequisitionsPCase;
              else if (tab == 'pipelineOps') tabLabelName = scope.clientStrings().homePipelineOpsPCase;
              else if (tab == 'interpreters') tabLabelName = scope.clientStrings().homeInterpretersPCase;
              else if (tab == 'financial') tabLabelName = scope.clientStrings().homeFinancialPCase;
              return {
                label: tabLabelName || tab,
                value: tab
              };
            });
          scope.supportedTaskTypes = false;
          if (settings.supportedTaskTypes && settings.supportedTaskTypes.indexOf(TASK_SCHEMA_TYPE_SURVEY) > -1) {
            scope.supportedTaskTypes = true;
          }
        });

        scope.setDefaultOffice = () => {
          if (scope.model.defaultOffice) {
            var defaultOffice = _.find(scope.officeItemsFiltered, function (officeItem) {
              return officeItem.value == scope.model.defaultOffice;
            })
            scope.user.defaultOffice = {
              id: defaultOffice.value,
              internalId: defaultOffice.internalId
            }
          } else {
            scope.user.defaultOffice = null
          }
        }

        scope.passwordStrengthValidator = (value, type) => {
          if (type == 'duress') {
            scope.duressPasswordStrength = $passwordStrengthValidator(value);
            scope.passwordStrength = null;
          } else {
            scope.duressPasswordStrength = null;
            scope.passwordStrength = $passwordStrengthValidator(value);
          }
        }

        scope.onDashboardTabChange = (tabVal) => {
          // scope.dashboardTabsItems.map(obj => {
          //   scope.childArr[obj.value] = obj.child.filter((obj) => obj.permissions)
          // });
          if (scope.defaultTabs && scope.model.dashboardItems && scope.defaultTabs[scope.model.dashboardItems]) {
            scope.defaultTabs[scope.model.dashboardItems] = scope.user.defaultDashboardTabs[scope.model.dashboardItems] || null;
          }
        }

        if (scope.user.defaultDashboardTabs) {
          scope.model.dashboardItems = scope.user.defaultDashboardView; //scope.dashboardTabsItems[0].value;
          scope.onDashboardTabChange(scope.model.dashboardItems);

        }

        scope.onTabChange = (val) => {
          if (val) {
            scope.user['defaultDashboardTabs'] = scope.defaultTabs;
          }
        }

        scope.checkInterpreterRole = () => {
          let role = scope.allRoles.find(item => item.value == scope.user.roles);
          scope.isCourtJudge = false;
          scope.isInterpreterRoleSelected = false;
          scope.isLegalAssitant = false;
          if (role && role.code == 'COURT_JUDGE') {
            scope.isCourtJudge = true;
            scope.loadUsersByRole('COURT_COORDINATOR')
          }
          if (role && role.code == 'COURT_COORDINATOR') {
            scope.isLegalAssitant = true;
          }
          if (role && role.code == 'INTERPRETER') {
            scope.isInterpreterRoleSelected = true;
            scope.user.allowRegions = scope.allValue;
            scope.user.allowOffices = scope.allValue;
            scope.user.defaultDashboardView = "interpreterResource";
            scope.model.dashboardItems = "interpreterResource";
            setTimeout(() => {
              scope.defaultTabs = {"interpreterResource": "open_work_orders"};
              scope.$apply()
            }, 300)
          } else {
            scope.isInterpreterRoleSelected = false;
            scope.user.defaultDashboardView = "";
            scope.model.dashboardItems = "";
            scope.defaultTabs = {};
          }
        }

        scope.ddSettings = function (type) {
          var officeStr = type;
          return {
            selectedStr: 'Selected ' + officeStr + ': ',
            noSelection: 'No ' + officeStr + ' Selected',
            allLabel: 'All ' + officeStr
          };
        };

        function getLanguages(client) {
          scope.activeLanguages = [];
          $clientLanguages.getLanguages(client)
            .then(({ data, status }) => {
              if (status) {
                data.forEach((obj) => {
                  if (obj && obj.isEnabled) {
                    scope.activeLanguages.push({
                      label: `${obj.name} (${obj.code})`,
                      value: obj._id
                    })
                  }
                })
              }
            })
        }

        scope.validateInternalId = function () {
          // if (!scope.user.internalId || scope.formUser.internal_id.$invalid) {
          //   if (scope.formUser && scope.formUser.internal_id && scope.formUser.internal_id.$setValidity) {
          //     scope.formUser.internal_id.$setValidity('unique', true);
          //   }
          //   return;
          // }
          if (scope.user.internalId) {
            $clientUser.isInternalIdUnique(scope.client, scope.user.internalId, scope.user.userId)
              .then(function (res) {
                if (res.status && scope.formUser && scope.formUser.internal_id && scope.formUser.internal_id.$setValidity) {
                  scope.formUser.internal_id.$setValidity('unique', res.data);
                }
                else {
                  if (scope.formUser && scope.formUser.internal_id && scope.formUser.email.$setValidity) {
                    scope.formUser.internal_id.$setValidity('unique', false);
                  }
                }
              });
          }
        };
        scope.sentInvitationMail = function () {
          if (!scope.user.sendWelcomeEmail) return;
          scope.user.sendingMail = true;
          const user = _.pick(scope.user, ['_id', 'name', 'email', 'internalId'])
          console.log(scope.user)
          $clientUser.sendWelcomMail(scope.client, user)
            .then((res) => {
              if (res.status) {
                ngToast.create({
                  content: 'Welcome Mail Sent',
                  className: 'success'
                });
              } else {
                ngToast.create({
                  content: 'Somthing went wrong!',
                  className: 'error'
                });
              }
            })
            .catch(err => {
              console.log(err)
              ngToast.create({
                content: err.message || 'Somthing went wrong!',
                className: 'error'
              });
            })
            .finally(() => {
              scope.user.sendingMail = false;
              scope.user.sendWelcomeEmail = false
            })
        }
        scope.onInternalIdChange = function () {
          scope.isInternalIdUpdate = false;
          if (scope.oldUser && scope.oldUser.internalId && scope.oldUser.internalId !== scope.user.internalId) {
            scope.isInternalIdUpdate = true;
          }
        }
      }
    };
  }
// Dependency Injection
formUserDirective.$inject = ["$rootScope","$q","$auth","$clientSwitcher","$clientUser","$clientRegion","$clientManager","$clientOffice","$clientCampaign","$client","$clientUserSetting","$timezones","$clientEmployee","$activeClientSettings","TASK_SCHEMA_TYPE_SURVEY","$clientRoles","$passwordStrengthValidator","$layoutItems","$removeAbility","$states","$clientLanguages","ngToast"];
