'use strict';

 export const DashboardSettingsUsersIndexCtrl = function ($rootScope, $scope, $auth, $clientUser, $clientSwitcher, $activeClient, $http, ngToast, Modal, $user, $clientRoles, DEFAULT_DEBOUNCE, appConfig, $showError) {

    $scope.loading = true;
    // $scope.searchText = "";
    $scope.model = {
      status: 'true',
      orderVal: 'true',
      searchText: '',
      userRole: '',
      isLoggedIn: 'all'
    }
    // $scope.loginSources = [
    //   {label: 'All', value: '', considerItem: true},
    //   {label: 'Mobile', value: 'mobile'},
    //   {label: 'Web', value: 'web'}
    // ];
    $scope.userRoles = [{ label: 'All', value: '', considerItem: true }];
    $scope.teamMembers = [];
    // $scope.userRole = '';
    $scope.activeMembers = 0;
    $scope.allowEditUser = false;

    var client = $clientSwitcher.getCurrentClient();
    // $scope.orderVal = 'true';
    $scope.sortingOrders = [{
      label: 'A-Z',
      value: 'true'
    }, {
      label: 'Z-A',
      value: 'false'
    }];

    $scope.loggedInOption = [{
      label: 'All',
      value: 'all'
    }, {
      label: 'Logged In',
      value: 'true'
    }, {
      label: 'Not Logged In',
      value: 'false'
    }];

    $scope.usersDataCount = 0;
    $scope.isLoading = false;
    $scope.error = null;
    $scope.pagination = {
      currentPage: 1,
      page: 1,
      total: 0,
      pageSize: 20,
      itemsPerPage: 20
    };
    $scope.filter = {};
    $scope.activeMembers = 0;

    $scope.allowEditUser = $auth.getCurrentUser().permissions.includes('SETTING_USER_EDIT');
    $scope.allowEditServicePortalUser = $auth.getCurrentUser().permissions.includes('SETTING_SERVICE_PORTAL_USER_EDIT');
    $scope.allowDeleteUser = $auth.getCurrentUser().permissions.includes('SETTING_USER_DELETE');

    $scope.reloadClientTeam = function () {
      if ($scope.isLoading) return;
      $scope.isLoading = true;
      $scope.error = null;
      $scope.teamMembers = [];
      // $scope.totalActiveLanguage = 0;
      $scope.pagination.total = 0;
      $scope.filter.orderByValue = $scope.model.orderVal == 'true' ? 'asc' : 'desc';
      $scope.filter.orderByField = 'name';
      $scope.filter.page = $scope.pagination.page;
      $scope.filter.pageSize = $scope.pagination.pageSize;
      $scope.filter.isEnabled = $scope.model.status;
      $scope.filter.isLoggedIn = $scope.model.isLoggedIn;
      if ($scope.model.searchText) {
        $scope.filter.searchKey = $scope.model.searchText;
      }
      if ($scope.model.userRole) {
        $scope.filter.userRole = $scope.model.userRole;
      }
      $clientUser.getUsersList($scope.client, $scope.filter)
        .then((res) => {
          if (res.status) {
            if (res.usersList && res.usersList.length) {
              $scope.teamMembers = res.usersList;
              $scope.pagination.total = res.totalDisplayRecords;
            } else {
              $scope.error = 'No User Found.';
            }
            $scope.usersDataCount = res.totalRecords || 0;
            $scope.disabledUser = res.disabled || 0;
            $scope.activeMembers = res.totalActiveUsers || 0;
            $scope.totalInactiveUsers = res.totalInactiveUsers || 0;
          }
        })
        .catch((err) => {
          let errMessage = err.message || err;
          if (err && err.data && err.data.message) {
            errMessage = err.data.message;
          } else if (err && err.data && err.data.reason) {
            errMessage = err.data.reason;
          }
          $scope.error = errMessage;
          $scope.usersDataCount = 0;
          $scope.activeMembers = 0;
          $scope.totalInactiveUsers = 0;
        })
        .finally(() => {
          $scope.isLoading = false;
        })
    }

    function reset() {
      $scope.pagination = {
        currentPage: 1,
        page: 1,
        total: 0,
        pageSize: 20,
        itemsPerPage: 20
      };
      $scope.filter = {};
    }

    $scope.applySearch = _.debounce(function (cv) {
      reset();
      $scope.reloadClientTeam();
    }, DEFAULT_DEBOUNCE)

    $scope.onSearch = function () {
      $scope.applySearch();
    }

    $scope.onSorting = function () {
      reset();
      $scope.reloadClientTeam();
    }

    $scope.onStatusChange = function () {
      reset();
      $scope.reloadClientTeam();
    }

    $scope.onRoleChange = function (e) {
      if (e) {
        reset();
        $scope.reloadClientTeam();
      }
    }

    $scope.getRoles = function () {
      $clientRoles.getRoles(client)
        .then(function (roles) {
          $scope.userRoles = $scope.userRoles.concat(roles.data.map(x => ({
            label: x.name,
            value: x._id
          })));
        })
    }

    function reloadClientTeam1(client) {
      $scope.loading = true;
      $scope.allowEditUser = $auth.getCurrentUser().permissions.includes('SETTING_USER_EDIT');
      $scope.allowEditServicePortalUser = $auth.getCurrentUser().permissions.includes('SETTING_SERVICE_PORTAL_USER_EDIT');

      $clientUser.list(client)
        .then(function (data) {
          $scope.teamMembers = data;
          $scope.activeMembers = 0;

          $scope.teamMembers.forEach(function (m) {
            if (m.isEnabled) $scope.activeMembers++;
          });
          return $clientRoles.getRoles(client);
        })
        .then(function (roles) {
          $scope.userRoles = $scope.userRoles.concat(roles.data.map(x => ({
            label: x.name,
            value: x._id
          })));
        })
        .finally(function () {
          $scope.loading = false;
        });
    }

    $scope.sendWelcomeEmail = function (user) {
      Modal.confirm.show("Email Invite", "Are you sure you want to send invite to <strong>" + user.name + "</strong>?", "Yes", "No", function () {
        $http.get(appConfig.apiUrl + '/api/users/' + client._id + '/' + user.userId + '/send-welcome-email').then(function (result) {
          if (result.data.status) {
            ngToast.create({
              className: 'success',
              content: 'Welcome E-mail sent successfully'
            });
          } else {
            ngToast.create({
              className: 'danger',
              content: 'Error' + result.data.reason
            });
          }
        });
      });
    };

    $scope.isMe = function (member) {
      var cUser = $auth.getCurrentUser();
      if (!member || !member.userId || !cUser) return false;
      return member.userId === cUser._id;
    };

    $scope.toggleAccess = function (member) {
      var modalTitle = (member.isEnabled ? 'Disable' : 'Enable') + ' user';
      var modalBody = "Are you sure you want to " + (member.isEnabled ? 'disable' : 'enable') + " <strong>" + (member.name) + "</strong> ?";
      var notificationContent = "User " + (member.isEnabled ? 'disabled' : 'enabled') + ' successfully';

      Modal.confirm.show(modalTitle, modalBody, "Yes", "No", function () {
        $user.toggleAccess(member.userId, client).then(function (result) {
          if (result.status) {
            ngToast.create({
              className: 'success',
              content: notificationContent
            });

            //set the new value after server confirmation
            member.isEnabled = !member.isEnabled;
          } else {
            ngToast.create({
              className: 'danger',
              content: 'Error' + result.reason
            });
          }
        })
          .catch((err) => {
            $showError(err);
          })
      });
    };

    $scope.removeUser = function (user) {
      if ($scope.isDeleting) return
      $scope.isDeleting = true;
      let msg = 'Are you sure you want to delete ' + user.name + ' user?'
      Modal.confirm.show('Remove User', msg, 'Yes', 'No', function () {
        $clientUser.removeUser(client, user._id)
          .then(res => {
            // console.log(res)
            // if (res.status)
            ngToast.create({
              content: res.message,
              className: 'success'
            });
            $scope.reloadClientTeam();
          })
          .catch(err => {
            console.log(err)
            ngToast.create({
              content: err.message || err,
              className: 'danger'
            });
          })
          .finally(() => {
            $scope.isDeleting = false;
          })
      })
    }

    // listen for client switching
    $scope.$on('$destroy', $activeClient(function (client) {
      if (!client) return;
      $scope.client = client;
      $scope.reloadClientTeam();
      $scope.getRoles();
    }));

    $scope.isAdmin = function (member) {
      if (member.roles && member.roles.length) {
        let isAdminRole = member.roles.filter((obj) => (obj.code == 'COORDINATE_ADMIN'));
        return !!isAdminRole.length;
      }
      return false;
    }

    $scope.clearUserConcurrentSession = function (member) {
      Modal.userSession.userConcurrentDetails(member, function (res) {
      })();
    }

    $scope.getDevices = function(member) {
      Modal.userModals.userDevice(client, member, function (res) {
      })();
    }
  }
// Dependency Injection
DashboardSettingsUsersIndexCtrl.$inject = ["$rootScope","$scope","$auth","$clientUser","$clientSwitcher","$activeClient","$http","ngToast","Modal","$user","$clientRoles","DEFAULT_DEBOUNCE","appConfig","$showError"];

// angular.module('coordinateApp').controller('DashboardSettingsUsersIndexCtrl', DashboardSettingsUsersIndexCtrl);
