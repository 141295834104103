


'use strict';

export const ClientInterpreterCourtDistanceFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/interpreter-court-distances/:id/:controller', {
            id: '@_id'
        },
            {
                list: {
                    method: 'GET',
                    params: {
                        controller: ''
                    }
                },
                interpreterCourtDistance: {
                    method: 'GET',
                    params: {
                        controller: 'interpreter-court-distance'
                    }
                },
                interpreterCourtDistanceByCourt: {
                    method: 'GET',
                    params: {
                        controller: 'interpreter-court-distance-by-court'
                    }
                },
                courtInterpreterDistance: {
                    method: 'GET',
                    params: {
                        controller: 'get-distnce-by-court-code'
                    }
                },
                syncDistanceByCourtCode: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'ic-sync-by-court'
                    }
                },
                syncDistance: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'sync-interpreter-distance'
                    }
                },
                interpreterSingleCourtDistanceSync: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'sync-specific-interpreter-court-distance'
                    }
                },
                pendingSyncList: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'pending-sync-list'
                    }
                }
            });
    } 
 

// Dependency Injection
ClientInterpreterCourtDistanceFactory.$inject = ['$resource', 'appConfig'];
