'use strict';
export const $eventsService = function (WO_Event, System_Event, Service_Portal_Event, Service_Event) {
  let events = [{
      label: 'Auto Interpreter Approval',
      value: 'Auto Interpreter Approval',
      type: WO_Event
  },
  {
      label: 'Extend Interpreter',
      value: 'Extend Interpreter',
      type: WO_Event
  },
  {
      label: 'Interpreter Release Request',
      value: 'Interpreter Release Request',
      type: WO_Event
  },
  {
      label: 'WO IC Request Release Approved',
      value: 'WO IC Request Release Approved',
      type: WO_Event
  },
  {
      label: 'Interpreter Apply for Open Work Order',
      value: 'Interpreter Apply for Open Work Order',
      type: WO_Event
  },
  {
      label: 'WO Interpreter Approved',
      value: 'WO Interpreter Approved',
      type: WO_Event
  },
  {
      label: 'WO Interpreter Disputed',
      value: 'WO Interpreter Disputed',
      type: WO_Event
  },
  {
      label: 'WO Court Approved',
      value: 'WO Court Approved',
      type: WO_Event
  },
  {
      label: 'WO Court Disputed',
      value: 'WO Court Disputed',
      type: WO_Event
  },
  {
      label: 'WO SOSi Accepted',
      value: 'WO SOSi Accepted',
      type: WO_Event
  },
  {
      label: 'WO SOSi Disputed',
      value: 'WO SOSi Disputed',
      type: WO_Event
  },
  {
      label: 'WO Comment Created',
      value: 'WO Comment Created',
      type: WO_Event
  },
  {
      label: 'WO Event Updated',
      value: 'WO Event Updated',
      type: WO_Event
  },
  {
      label: 'Release Interpreter',
      value: 'Release Interpreter',
      type: WO_Event
  },
  {
      label: 'Interpreter Assigned',
      value: 'Interpreter Assigned',
      type: WO_Event
  }, {
      label: 'Interpreter Withdraw',
      value: 'Interpreter Withdraw',
      type: WO_Event
  }, {
      label: 'Interpreter Instruction Added',
      value: 'Interpreter Instruction Added',
      type: WO_Event
  }, {
      label: 'Interpreter Request to Withdraw Work Order',
      value: 'Interpreter Request to Withdraw Work Order',
      type: WO_Event
  }, {
      label: 'WO Cancel',
      value: 'WO Cancel',
      type: WO_Event
  },
  // {
  //     label: 'Interpreter Assigns for Open Work Orders',
  //     value: 'Interpreter Assigns for Open Work Orders',
  //     type: WO_Event
  // },
  {
      label: 'Interpreter Assigned To Work Order',
      value: 'Interpreter Assigned To Work Order',
      type: WO_Event
  // },{
  //     label: 'Interpreter Confirm for Open Work Orders',
  //     value: 'Interpreter Confirm for Open Work Orders',
  //     type: WO_Event
  },
  {
      label: 'Interpreter Confirm',
      value: 'Interpreter Confirm',
      type: WO_Event
  },
  {
      label: 'Interpreter Reject for Open Work Orders',
      value: 'Interpreter Reject for Open Work Orders',
      type: WO_Event
  },
  {
      label: 'Interpreter OPI FIN',
      value: 'Interpreter OPI FIN',
      type: WO_Event
  },
  {
      label: 'Update WO Language',
      value: 'Update WO Language',
      type: WO_Event
  },
  {
      label: 'Update WO Service Type',
      value: 'Update WO Service Type',
      type: WO_Event
  },
  {
      label: 'Update WO Hearing Type',
      value: 'Update WO Hearing Type',
      type: WO_Event
  },
  {
      label: 'Update WO Scheduled Time',
      value: 'Update WO Scheduled Time',
      type: WO_Event
  },
  {
      label: 'Update WO Court',
      value: 'Update WO Court',
      type: WO_Event
  },
  {
      label: 'Update WO Judge',
      value: 'Update WO Judge',
      type: WO_Event
  },
  {
      label: 'Update WO Custody',
      value: 'Update WO Custody',
      type: WO_Event
  },
  {
      label: 'Update WO Coordinator',
      value: 'Update WO Coordinator',
      type: WO_Event
  },
  // {
  //     label: 'WO Created',
  //     value: 'WO Created',
  //     type: WO_Event
  // },
  // {
  //     label: 'WO Updated',
  //     value: 'WO Updated',
  //     type: WO_Event
  // },
  {
      label: 'Interpreter Profile Updated',
      value: 'Interpreter Profile Updated',
      type: System_Event
  },
  {
      label: 'Judge Enabled',
      value: 'Judge Enabled',
      type: System_Event
  },
  {
      label: 'Judge Disabled',
      value: 'Judge Disabled',
      type: System_Event
  },
  {
      label: 'Language Updated',
      value: 'Language Updated',
      type: System_Event
  },
  {
      label: 'Nationality Updated',
      value: 'Nationality Updated',
      type: System_Event
  },
  // {
  //     label: 'Office Updated',
  //     value: 'Office Updated',
  //     type: System_Event
  // },
  {
      label: 'Region Updated',
      value: 'Region Updated',
      type: System_Event
  },
  {
      label: 'Region Created',
      value: 'Region Created',
      type: System_Event
  },
  {
      label: 'Reporting Court Updated',
      value: 'Reporting Court Updated',
      type: System_Event
  },
  {
      label: 'Reporting Court Created',
      value: 'Reporting Court Created',
      type: System_Event
  },
  {
      label: 'Report Library File Updated',
      value: 'Report Library File Updated',
      type: System_Event
  },
  {
      label: 'Report Library File Deleted',
      value: 'Report Library File Deleted',
      type: System_Event
  },
  {
      label: 'Report Library Folder Updated',
      value: 'Report Library Folder Updated',
      type: System_Event
  },
  {
      label: 'Report Library Folder Deleted',
      value: 'Report Library Folder Deleted',
      type: System_Event
  },
  {
      label: 'Role Updated',
      value: 'Role Updated',
      type: System_Event
  },
  {
      label: 'Service Comment Created',
      value: 'Service Comment Created',
      type: Service_Portal_Event
  },
  {
      label: 'Service Portal User Created',
      value: 'Service Portal User Created',
      type: Service_Portal_Event
  },
  {
      label: 'Service Portal User Accepted',
      value: 'Service Portal User Accepted',
      type: Service_Portal_Event
  },
  {
      label: 'Service Job Comment Added',
      value: 'Service Job Comment Added',
      type: Service_Portal_Event
  },
  {
      label: 'Service Job Created',
      value: 'Service Job Created',
      type: Service_Portal_Event
  },
  {
      label: 'Service User Job Updated',
      value: 'Service User Job Updated',
      type: Service_Portal_Event
  },
  {
      label: 'User Disabled',
      value: 'User Disabled',
      type: System_Event
  },
  {
      label: 'User Enabled',
      value: 'User Enabled',
      type: System_Event
  },
  {
      label: 'User Role Updated',
      value: 'User Role Updated',
      type: System_Event
  },
  // {
  //     label: 'User Session Deleted',
  //     value: 'User Session Deleted',
  //     type: System_Event
  // },
  {
      label: 'User Updated',
      value: 'User Updated',
      type: System_Event
  },
  {
      label: 'Request Interpreter',
      value: 'Request Interpreter',
      type: System_Event
  },
  {
      label: 'Request Interpreter Assigned',
      value: 'Request Interpreter Assigned',
      type: System_Event
  },
  {
      label: 'Request Interpreter Cancel',
      value: 'Request Interpreter Cancel',
      type: System_Event
  },
  {
      label: 'Quote Requested',
      value: 'Quote Requested',
      type: Service_Portal_Event
  },
  {
      label: 'Modified Quote',
      value: 'Modified Quote',
      type: Service_Portal_Event
  },
  {
      label: 'Quote Approved',
      value: 'Quote Approved',
      type: Service_Portal_Event
  },
  {
      label: 'Work In Process',
      value: 'Work In Process',
      type: Service_Portal_Event
  },
  {
      label: 'Work Completed',
      value: 'Work Completed',
      type: Service_Portal_Event
  },
  {
      label: 'Work Accepted',
      value: 'Work Accepted',
      type: Service_Portal_Event
  },
  {
      label: 'Invoice Generated',
      value: 'Invoice Generated',
      type: Service_Portal_Event
  },
  {
      label: 'Payment Sent',
      value: 'Payment Sent',
      type: Service_Portal_Event
  },
  {
      label: 'Payment Received',
      value: 'Payment Received',
      type: Service_Portal_Event
  },
  {
      label: 'Service Completed',
      value: 'Service Completed',
      type: Service_Portal_Event
  },
  {
      label: 'Attachment Added',
      value: 'Attachment Added',
      type: Service_Portal_Event
  },
  {
      label: 'Service Past Due Date',
      value: 'Service Past Due Date',
      type: Service_Portal_Event
  },

  {
      label: 'COI Email Reminder Service',
      value: 'COI Email Reminder Service',
      type: Service_Event
  }
  ];
  return _.sortBy(events, ['label']);;
}
// Dependency Injection

$eventsService.$inject = ["WO_Event","System_Event","Service_Portal_Event","Service_Event"];
