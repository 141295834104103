'use strict';

export const pendingInterpreterDistancesDirective = function ($auth,$clientSwitcher, $clientInterpreterCourtDistance, Modal, ngToast) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/settings/interpreter-court-distances/pending-interpreter-distances/pending-interpreter-distances.html',
            scope: {

            },
            link: function ($scope) {
                $scope.data = {
                    isLoading: false,
                    error: null,
                    result: [],
                    totalCourts: 0
                }

                $scope.error = null;
                $scope.oneAtATime = true;
                $scope.filter = {
                    searchText: null
                };
                $scope.userPermissions = null;

                $scope.currentCli = $clientSwitcher.getCurrentClient();
                $scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageLimit: 10,
                    itemsPerPage: 10
                };

                function init() {
                    $scope.userPermissions = $auth.getCurrentUser().permissions;
                    $scope.data.permissions = {
                        allowSync: $scope.userPermissions.includes('SETTING_SYNC_INTERPRETER_COURT_DISTANCE'),
                    }
                    $scope.getPendingInterpreterDistance()
                }
                $scope.getPendingInterpreterDistance = function() {
                    $scope.data.isLoading = true;
                    $clientInterpreterCourtDistance.pendingSyncList($scope.currentCli, {})
                        .then((res) => {
                            if (res.status) {
                                $scope.data.result = res.result;
                                $scope.pagination.total = res.result.length;
                                $scope.data.totalCourts = res.activeCourts;
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => {
                            $scope.data.isLoading = false;
                        })
                }

                $scope.applySearch = _.debounce(function (cv) {
                    reset()
                }, 0);

                $scope.onSearch = () => {
                    $scope.applySearch();
                }

                function reset() {
                    $scope.pagination = {
                        currentPage: 1,
                        page: 1,
                        total: 0,
                        pageLimit: 10,
                        itemsPerPage: 10
                    };
                    $scope.getPendingInterpreterDistance()
                }

                $scope.getDistanceCount = (distances) => {
                    if (distances && distances.length) {
                        let invalidDistance = distances.filter((item) => item.distance > 0);
                        return invalidDistance.length
                    } else {
                        return 0
                    }
                }
                $scope.showDistanceModal = (court) => {
                    // console.log($scope.data.allCourts)
                    Modal.interpreterProfileActions.interpreterDistance(court, $scope.data.allInterpreterUsers, function (res) {
                        // console.log(res)
                    })();
                }

                $scope.syncDistance = (icObj) => {
                    icObj.distanceSync = true;
                    // let allOfficesGroup = _.groupBy($scope.data.allCourts, 'internalId');
                    let reqInterpreter = {
                        vendorId: icObj.vendorId,
                        name: icObj.name,
                        address : icObj.address,
                    }
        
                    $clientInterpreterCourtDistance.syncDistance($scope.currentCli, reqInterpreter)
                        .then((res) => {
                            if (res.status) {
                                ngToast.create({
                                    content: res.message,
                                    className: 'success'
                                });
                            } else {
                                ngToast.create({
                                    content: res.message,
                                    className: 'danger'
                                });
                            }
                        })
                        .catch(err => {
                            ngToast.create({
                                content: err && err.data && err.data.reason ? err.data.reason : 'something went wrong please try again',
                                className: 'danger'
                            });
                            console.error(err);
                        })
                        .finally(() => {
                            $scope.getPendingInterpreterDistance()
                            icObj.distanceSync = false;
                        })
                }
                init()

            }
        }
    }
// Dependency Injection
pendingInterpreterDistancesDirective.$inject = ["$auth","$clientSwitcher","$clientInterpreterCourtDistance","Modal","ngToast"];
