'use strict';

import './app.scss';
import './app.layout.scss';

import $ from 'jquery';
window.$ = window.jQuery = $;
// import moment from 'moment';
import angular from 'angular';
// window.moment = moment;
window.angular = angular;
import { connect } from 'socket.io-client';

import 'angular-resource';
import 'angular-sanitize';
import 'angular-messages';
import 'angular-ui-router';
import 'angular-route';


import 'angular-ui-bootstrap';
import 'angular-file-upload';
import 'angular-tiny-eventemitter';
import 'angular-simple-logger';
import 'angular-messages';
import 'ui-select';
import 'angular-animate';
import 'angular-touch';
import 'angular-cookies';
import '../bower_components/utilities.js/index.js'
import '../bower_components/angular-utilities.js/angular-utilities.js';



import 'pivottable';
import 'jquery-ui';
import 'angular-rangeslider';
import 'angular-ui-sortable';
import 'angular-ui-tree';
import 'angular-input-masks';
// import 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
// import 'angular-moment';
import 'string-mask';
import 'br-masks';
import 'ng-idle';
import 'jsbarcode';
import 'angular-drag-and-drop-lists';
import 'angular-socket-io';
import 'file-saver';
import 'oclazyload';


import '../../node_modules/angular-media-player/dist/angular-media-player.js';
import '../bower_components/datetimeRangePicker/range-picker.js'
import '../bower_components/ngToast/dist/ngToast.js';
import '../bower_components/bootstrap-toggle/js/bootstrap-toggle.min.js';
import '../bower_components/datetimeRangePicker/range-picker.js';
import '../bower_components/ng-img-crop/compile/minified/ng-img-crop.js';
import '../bower_components/angular-bootstrap-toggle-switch/angular-toggle-switch.js';
import '../bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
// import '../bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import '../bower_components/bootstrap-treeview/dist/bootstrap-treeview.min.js';
import '../bower_components/ng-table/dist/ng-table.min.js';
import '../bower_components/ng-Map/dist/ng-map.js';
import '../bower_components/angular-br-filters/release/angular-br-filters.js';
import '../bower_components/amazon-cognito-identity-js/amazon-cognito-identity.min.js';
import '../bower_components/venturocket-angular-slider/build/angular-slider.js';

import loadScript from '../utils/loadScript.js'

import configENV from './configENV';
import { AccountClientsCtrl } from './account/clients/client/client.controller';
import { AccountLoginCtrl } from './account/login/login.controller.js'
import { DashboardSettingsBucketsIndexCtrl } from './account/dashboard/settings/buckets/index/index.controller';
import { DashboardSettingsCampaignsIndexCtrl } from './account/dashboard/settings/campaigns/index/index.controller';
import { DashboardSettingsCustomersIndexCtrl } from './account/dashboard/settings/customers/index/index.controller';
import { DashboardSettingsDevicesIndexCtrl } from './account/dashboard/settings/devices/index/index.controller';
import { DashboardSettingsEmployeesIndexCtrl } from './account/dashboard/settings/employees/index/index.controller';
import { DashboardSettingsEquipmentsIndexCtrl } from './account/dashboard/settings/equipments/index/index.controller';
import { DashboardSettingsInventorysIndexCtrl } from './account/dashboard/settings/inventorys/index/index.controller';
import { DashboardSettingsJudgesIndexCtrl } from './account/dashboard/settings/judges/index/index.controller';
import { DashboardSettingsLanguagesIndexCtrl } from './account/dashboard/settings/languages/index/index.controller';
import { DashboardSettingsLogsIndexCtrl } from './account/dashboard/settings/logs/index/index.controller';
import { DashboardSettingsMobileAppsIndexCtrl } from './account/dashboard/settings/mobile_apps/index/index.controller';
import { DashboardSettingsNationalityIndexCtrl } from './account/dashboard/settings/nationality/index/index.controller';
import { DashboardSettingsNotificationManageIndexCtrl } from './account/dashboard/settings/notifications-management/index/index.controller';
import { DashboardSettingsOffManageIndexCtrl } from './account/dashboard/settings/offering-management/index/index.controller';
import { DashboardSettingsOfficesIndexCtrl } from './account/dashboard/settings/offices/index/index.controller';
import { DashboardSettingsPermissionsIndexCtrl } from './account/dashboard/settings/permissions/index/index.controller';
import { PostgresDataSyncCasesCtrl } from './account/dashboard/settings/postgres_data_sync/cases/cases.controller';
import { DashboardSettingsProgramIndexCtrl } from './account/dashboard/settings/program/index/index.controller';
import { DashboardSettingsRegionsIndexCtrl } from './account/dashboard/settings/regions/index/index.controller';
import { DashboardSettingsReportBucketsIndexCtrl } from './account/dashboard/settings/report_buckets/index/index.controller';
import { DashboardSettingsResolveCodesIndexCtrl } from './account/dashboard/settings/resolve_codes/index/index.controller';
import { DashboardSettingsRolesIndexCtrl } from './account/dashboard/settings/roles/index/index.controller';
import { DashboardSettingsRulesIndexCtrl } from './account/dashboard/settings/rules/index/index.controller';
import { DashboardSettingsIndexCtrl } from './account/dashboard/settings/index/index.controller';
import { DashboardSettingsTablesIndexCtrl } from './account/dashboard/settings/tables/index/index.controller';
import { DashboardSettingsUsersIndexCtrl } from './account/dashboard/settings/users/index/index.controller';
import { ServicePanelIndexCtrl } from './account/services/service-panel/index/index.controller';
import { AccountConfig, AccountCtrl  }  from './account/account.js';
import { phoneNumberFilter  }  from './account/dashboard/contact/phoneNumberFilter.js';
import { $layoutItemsService   }  from './account/dashboard/main-dashboard/main-dashboard.service.js';
import { staticParamsFactory  }  from './account/dashboard/main-dashboard/main-dashboard.factory.js';
import {  SatisfactionContainerCtrl ,  SatisfactionEntryCtrl ,  satisfactionContainerDirective ,  satisfactionEntryDirective  }  from './account/dashboard/satisfaction/satisfaction.directives.js';
import { pluginsFactory  }  from './account/dashboard/settings/index/index.factory.js';
import {  DashboardSettingsManagersCreateCtrl  }  from './account/dashboard/settings/managers/create/create.controller.js';
import {  DashboardSettingsManagersIndexCtrl  }  from './account/dashboard/settings/managers/index/index.controller.js';
import {  DashboardSettingsManagersCtrl  }  from './account/dashboard/settings/managers/managers.controller.js';
import {  DashboardSettingsManagersUpdateCtrl  }  from './account/dashboard/settings/managers/update/update.controller.js';
import { $eventsService  }  from './account/dashboard/settings/notifications-management/index/index.service.js';
import { setRuleValueFilter  }  from './account/dashboard/settings/offering-management/index/index.filter.js';
import { $sessionItemsService  }  from './account/dashboard/settings/offices/create/create.service.js';
import { inArrayFilter  }  from './account/dashboard/settings/offices/index/index.filter.js';
import { $permissionMatrixSourceTypeService  }  from './account/dashboard/settings/permission-matrix/permission-matrix.service.js';
import { usPhoneNumberFilter  }  from './account/dashboard/settings/postgres_data_sync/telephonics/telephonics.filter.js';
import {  DashboardSettingsSurveyScoreIndexCtrl  }  from './account/dashboard/settings/survey_score/index/index.controller.js';
import { usersWithRoleFilter ,  usersWithStatusFilter  }  from './account/dashboard/settings/users/index/index.filter.js';
import {  DashboardTasksWidgetCtrl ,  dashboardTasksDirective ,  ChartDataFactory  }  from './account/dashboard/tasks/tasks.widget-directive.js';
import { $afterCognitoAuthLoginService  }  from './account/login/login.service.js';
import { humanizeStringFilter ,  getFirstLatterFilter, ServiceDetailCtrl  }  from './account/services/service-panel/service-detail/service-detail.controller.js';
import { $updateSCSSVariablesService  }  from './account/settings/acessibility_setting/acessibility-setting.service.js';
import {  AdminIndexCtrl  }  from './admin/index/index.controller.js';
import {  AdminUsersIndexCtrl  }  from './admin/users/index/index.controller.js';
import { $commonPrintService  }  from './print/office-operations/coti/coti.service.js';
import { $commonPrintService as $commonPrintService1 }  from './print/office-operations/covi/covi.service.js';
import { activityFilter  }  from './print/office-operations/ecoi/ecoi.filter.js';
import {  printTaskCtrl1Ctrl  }  from './print/task/task.controller.js';
import {  SupportCtrl  }  from './support/support.controller.js';
import {  selectionFilter  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/open-work-orders/selection.filter.js';
import {  connectionStatusDirective  }  from './account/dashboard/connection-status/connection-status.directive.js';
import {  assignmentStatusReportDirective  }  from './account/dashboard/live-reports/tabs/assignment-status-report/assignment-status-report.directive.js';
import {  mainCourtOperationsDirective  }  from './account/dashboard/main-dashboard/court-operations/main-court-operations.directive.js';
import {  headerMenuDirective  }  from './account/dashboard/main-dashboard/header/header.directive.js';
import {  interpreterResourceDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/interpreter-resource.directive.js';
import {  communicationsDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/communications/communications.directive.js';
import {  contractDocsDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/contract-docs/contract-docs.directive.js';
import {  payHistoryDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/pay-history/pay-history.directive.js';
import {  interpreterCourtDistanceDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/court-distance/ic-court-distance.directive.js';
import {  interpreterDocumentDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/document/document.directive.js';
import {  interpreterDqsDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/dqs/ic-dqs.directive.js';
import {  interpreterLanguagesDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/languages/ic-languages.directive.js';
import {  ratesDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/rates.directive.js';
import {  interpreterTravelDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/travel/travel.directive.js';
import {  trainingDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/training/training.directive.js';
import {  workHistoryDirective  }  from './account/dashboard/main-dashboard/interpreter-resource/tabs/work-history/work-history.directive.js';
import {  piplineOperationsDirective  }  from './account/dashboard/main-dashboard/pipline-operations/pipline-operations.directive.js';
import {  recruitingDirective  }  from './account/dashboard/main-dashboard/pipline-operations/tabs/recruiting/recruiting.directive.js';
import {  resourceAnalyticsDirective  }  from './account/dashboard/main-dashboard/pipline-operations/tabs/resource-analytics/resource-analytics.directive.js';
import {  workflowDirective  }  from './account/dashboard/main-dashboard/pipline-operations/tabs/workflow/workflow.directive.js';
import {  programSupportDirective  }  from './account/dashboard/main-dashboard/program-support/program-support.directive.js';
import {  sosiOneReportingDirective  }  from './account/dashboard/main-dashboard/sosi-one-reporting/sosi-one-reporting.directive.js';
import {  analyticsDirective  }  from './account/dashboard/main-dashboard/sosi-one-reporting/tabs/analytics/analytics.directive.js';
import {  liveReportsDirective  }  from './account/dashboard/main-dashboard/sosi-one-reporting/tabs/live-reports/live-reports.directive.js';
import {  recruitingReportDirective  }  from './account/dashboard/main-dashboard/sosi-one-reporting/tabs/recruiting-report/recruiting-report.directive.js';
import {  assignmentDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/assignment/assignment.directive.js';
import {  autoAssignedCountDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/auto-assigned-count/auto-assigned-count.directive.js';
import {  autoAssignedReportDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/auto-assigned/auto-assigned.directive.js';
import {  coiTimeReportDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/coi-time-report/coi-time-report.directive.js';
import {  interpreterAvailableWosDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/interpreter-available-wos/interpreter-available-wos.directive.js';
import {  interpreterNextDayScheduledDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/interpreter-next-day-scheduled/interpreter-next-day-scheduled.directive.js';
import {  interpreterScheduledNotificationDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/interpreter-scheduled-notification/interpreter-scheduled-notification.directive.js';
import {  paymentHistoryDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/payment-history/payment-history.directive.js';
import {  travelDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/travel/travel.directive.js';
// import {  woForecastingDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/wo-forecasting/wo-forecasting.directive.js';
import {  woInvoicesDirective  }  from './account/dashboard/main-dashboard/work-orders/tabs/wo-invoices/wo-invoices.directive.js';
import {  workOrdersDirective  }  from './account/dashboard/main-dashboard/work-orders/work-orders.directive.js';
import {  clientDeviceDirective  }  from './account/dashboard/settings/devices/index/device.directive.js';
import {  courtInterpreterDistanceDirective  }  from './account/dashboard/settings/interpreter-court-distances/court-interpreter-distance/court-interpreter-distance.directive.js';
import {  pendingInterpreterDistancesDirective  }  from './account/dashboard/settings/interpreter-court-distances/pending-interpreter-distances/pending-interpreter-distances.directive.js';
import {  roleDirective  }  from './account/dashboard/settings/permission-matrix/tabs/role/role.directive.js';
import {  screenDirective  }  from './account/dashboard/settings/permission-matrix/tabs/screen/screen.directive.js';
import {  userDirective  }  from './account/dashboard/settings/permission-matrix/tabs/user/user.directive.js';
import {  auditLogsDirective  }  from './account/dashboard/settings/postgres_data_sync/audit_logs/audit_logs.directive.js';
import {  clientRuleDirective  }  from './account/dashboard/settings/rules/index/rule.directive.js';
import {  calendarDirective  }  from './account/dashboard/tasks/tabs/calendar/calendar.directive.js';
import {  financialDirective  }  from './account/dashboard/tasks/tabs/financial/financial.directive.js';
import {  interpretersDirective  }  from './account/dashboard/tasks/tabs/interpreters/interpreters.directive.js';
import {  resolveCodesTableDirective  }  from './account/dashboard/tasks/tabs/organization/performance/resolve-codes/resolve-codes.directive.js';
import {  pipelineOpsDirective  }  from './account/dashboard/tasks/tabs/pipeline-ops/pipeline-ops.directive.js';
import {  reportsDirective  }  from './account/dashboard/tasks/tabs/reports/reports.directive.js';
import {  requisitionsDirective  }  from './account/dashboard/tasks/tabs/requisitions/requisitions.directive.js';
import {  leaveTableDirective  }  from './account/dashboard/time-production-reporting/tables/leave/leave.directive.js';
import {  productionTableDirective  }  from './account/dashboard/time-production-reporting/tables/production/production.directive.js';
import {  travelTableDirective  }  from './account/dashboard/time-production-reporting/tables/travel/travel.directive.js';
import {  workTableDirective  }  from './account/dashboard/time-production-reporting/tables/work/work.directive.js';
import {  invoicePanelDirective  }  from './account/services/invoices/invoices-panel/invoices-panel.directive.js';
import {  notificationManagementDirective  }  from './account/settings/notification_setting/notification/notification.directive.js';
import {  userNotificationDirective  }  from './account/settings/notification_setting/user_notification/user_notification.directive.js';
import { elisaPipelineCountsDirective } from './account/dashboard/settings/postgres_data_sync/elisa_pipeline_counts/elisa_pipeline_counts.directive.js'
import { multiSelectDirective } from '../components/multi-select/multi-select.directive.js'
import { singleSelectDirective } from '../components/single-select/single-select.directive.js'

import {  ClientConfig  }  from './account/clients/client/client.js';
import {  ClientsConfig  }  from './account/clients/clients.js';
import {  DatabaseConfig  }  from './account/clients/database/database.js';
import {  AdHocConfig  }  from './account/dashboard/ad-hoc/ad-hoc.js';
import {  AnalysisConfig  }  from './account/dashboard/analysis/analysis.js';
import {  AssignmentsConfig  }  from './account/dashboard/assignments/assignments.js';
import {  ContactConfig  }  from './account/dashboard/contact/contact.js';
import {  DashboardConfig  }  from './account/dashboard/dashboard.js';
import {  InterpreterResourcesConfig  }  from './account/dashboard/interpreter-resources/interpreter-resources.js';
import {  KioskConfig  }  from './account/dashboard/kiosk/kiosk.js';
import {  LiveReportsConfig  }  from './account/dashboard/live-reports/live-reports.js';
import {  MainDashboardConfig  }  from './account/dashboard/main-dashboard/main-dashboard.js';
import {  MissedAptsConfig  }  from './account/dashboard/missed-apts/missed-apts.js';
import {  EmailConfig  }  from './account/dashboard/notification-logs/email/email.js';
import {  NotificationLogsConfig  }  from './account/dashboard/notification-logs/notification-logs.js';
import {  PushConfig  }  from './account/dashboard/notification-logs/push/push.js';
import {  SmsConfig  }  from './account/dashboard/notification-logs/sms/sms.js';
import {  OfficeInformationConfig  }  from './account/dashboard/office-information/office-information.js';
import {  OfficeOperationsConfig  }  from './account/dashboard/office-operations/office-operations.js';
import {  OnDemandContactConfig  }  from './account/dashboard/on-demand-contact/on-demand-contact.js';
import {  OtcAnyWhereConfig  }  from './account/dashboard/otc-any-where/otc-any-where.js';
import {  PatientContactConfig  }  from './account/dashboard/patient-contact/patient-contact.js';
import {  ProbMissedAptsConfig  }  from './account/dashboard/prob-missed-apts/prob-missed-apts.js';
import {  ProgramsConfig  }  from './account/dashboard/programs/programs.js';
import {  RankingConfig  }  from './account/dashboard/ranking/ranking.js';
import {  RemoteWorkConfig  }  from './account/dashboard/remote-work/remote-work.js';
import {  ReportsConfig  }  from './account/dashboard/reports/reports.js';
import {  SatisfactionConfig  }  from './account/dashboard/satisfaction/satisfaction.js';
import {  ScheduleTasksConfig  }  from './account/dashboard/schedule-tasks/schedule-tasks.js';
import {  BucketsConfig  }  from './account/dashboard/settings/buckets/buckets.js';
import {  CampaignsConfig  }  from './account/dashboard/settings/campaigns/campaigns.js';
import {  CustomersConfig  }  from './account/dashboard/settings/customers/customers.js';
import {  DevicesConfig  }  from './account/dashboard/settings/devices/devices.js';
import {  EmployeesConfig  }  from './account/dashboard/settings/employees/employees.js';
import {  EquipmentsConfig  }  from './account/dashboard/settings/equipments/equipments.js';
import {  InterpreterCourtDistancesConfig  }  from './account/dashboard/settings/interpreter-court-distances/interpreter-court-distances.js';
import {  InventorysConfig  }  from './account/dashboard/settings/inventorys/inventorys.js';
import {  JudgesConfig  }  from './account/dashboard/settings/judges/judges.js';
import {  LanguagesConfig  }  from './account/dashboard/settings/languages/languages.js';
import {  LogsConfig  }  from './account/dashboard/settings/logs/logs.js';
import {  MobileAppsConfig  }  from './account/dashboard/settings/mobile_apps/mobile-apps.js';
import {  NationalityConfig  }  from './account/dashboard/settings/nationality/nationality.js';
import {  NotificationsManagementConfig  }  from './account/dashboard/settings/notifications-management/notifications-management.js';
import {  OfferingManagementConfig  }  from './account/dashboard/settings/offering-management/offering-management.js';
import {  OfficesConfig  }  from './account/dashboard/settings/offices/offices.js';
import {  PermissionMatrixConfig  }  from './account/dashboard/settings/permission-matrix/permission-matrix.js';
import {  PermissionsConfig  }  from './account/dashboard/settings/permissions/permissions.js';
import {  Postgres_data_syncConfig  }  from './account/dashboard/settings/postgres_data_sync/postgres_data_sync.js';
import {  ProgramConfig  }  from './account/dashboard/settings/program/program.js';
import {  RegionsConfig  }  from './account/dashboard/settings/regions/regions.js';
import {  Report_bucketsConfig  }  from './account/dashboard/settings/report_buckets/report_buckets.js';
import {  ResolveCodeConfig  }  from './account/dashboard/settings/resolve_codes/resolve-code.js';
import {  RolesConfig  }  from './account/dashboard/settings/roles/roles.js';
import {  RulesConfig  }  from './account/dashboard/settings/rules/rules.js';
import {  SettingsConfig  }  from './account/dashboard/settings/settings.js';
import {  Survey_scoreConfig  }  from './account/dashboard/settings/survey_score/survey_score.js';
import {  SystemConfig  }  from './account/dashboard/settings/system/system.js';
import {  TablesConfig  }  from './account/dashboard/settings/tables/tables.js';
import {  UsersSessionConfig  }  from './account/dashboard/settings/users-session/users-session.js';
import {  UsersConfig  }  from './account/dashboard/settings/users/users.js';
import {  SosEmailInformationConfig  }  from './account/dashboard/sos-email-information/sos-email-information.js';
import {  SurveysConfig  }  from './account/dashboard/surveys/surveys.js';
import {  TaskCreateRulesConfig  }  from './account/dashboard/task-create-rules/task-create-rules.js';
import {  HistoryConfig  }  from './account/dashboard/task-history/history.js';
import {  TasksConfig  }  from './account/dashboard/tasks/tasks.js';
import {  TimeProductionReportingLogConfig  }  from './account/dashboard/time-production-reporting-log/time-production-reporting-log.js';
import {  TimeProductionReportingConfig  }  from './account/dashboard/time-production-reporting/time-production-reporting.js';
import {  TrainingConfig  }  from './account/dashboard/training/training.js';
import {  UnconfirmedConfig  }  from './account/dashboard/unconfirmed/unconfirmed.js';
import {  UploadInterpreterPicsConfig  }  from './account/dashboard/upload-interpreter-pics/upload-interpreter-pics.js';
import {  WorkflowConfig  }  from './account/dashboard/workflow/workflow.js';
import {  LoginConfig  }  from './account/login/login.js';
import {  BillingMethodConfig  }  from './account/services/billing-method/billing-method.js';
import {  InvoicesConfig  }  from './account/services/invoices/invoices.js';
import {  ProjectsConfig  }  from './account/services/projects/projects.js';
import {  InvoicesPanelConfig  }  from './account/services/service-panel/invoices-panel/invoices-panel.js';
import {  ServicePanelConfig  }  from './account/services/service-panel/service-panel.js';
import {  ServicesConfig  }  from './account/services/services.js';
import {  SigninConfig  }  from './account/services/signin/signin.js';
import {  SignupConfig  }  from './account/services/signup/signup.js';
import {  SettingsConfig as SettingsConfig1 }  from './account/settings/settings.js';
import {  AdminConfig  }  from './admin/admin.js';
import {  ClientsConfig as ClientsConfig1 }  from './admin/clients/clients.js';
import {  UsersConfig as UsersConfig1 }  from './admin/users/users.js';
import {  ErrorConfig  }  from './error/error.js';
import {  ExternalConfig  }  from './external/external.js';
import {  RejectConfig  }  from './external/reject/reject.js';
import {  ResolveConfig  }  from './external/resolve/resolve.js';
import {  MainConfig  }  from './main/main.js';
import {  DqsReportsConfig  }  from './print/dqs-reports/dqs-reports.js';
import {  FulfillmentOpsDashboardConfig  }  from './print/fulfillment-operations/dashboard/fulfillment-ops-dashboard.js';
import {  InterpreterMatrixConfig  }  from './print/interpreter-matrix/interpreter-matrix.js';
import {  AssignmentReportConfig  }  from './print/live-reports/assignment-report/assignment-report.js';
import {  DailyReportConfig  }  from './print/live-reports/daily-report/daily-report.js';
import {  AssignmentStatusReportConfig  }  from './print/live-reports/monthly-reports/assignment-status-report/assignment-status-report.js';
import {  CotiConfig  }  from './print/office-operations/coti/coti.js';
import {  CoviConfig  }  from './print/office-operations/covi/covi.js';
import {  EcoiConfig  }  from './print/office-operations/ecoi/ecoi.js';
import {  ElogConfig  }  from './print/office-operations/elog/elog.js';
import {  PrintConfig  }  from './print/print.js';
import {  ResourceOpsDashboardConfig  }  from './print/resource-ops-dashboard/resource-ops-dashboard.js';
import {  InvoicePrintConfig  }  from './print/services/invoice-print/invoice-print.js';
import {  TaskOrgPerformanceConfig  }  from './print/task-org-performance/task-org-performance.js';
import {  TaskConfig  }  from './print/task/task.js';
import {  TimeReportingConfig  }  from './print/time-reporting/time-reporting.js';
import {  SupportConfig  }  from './support/support.js';
import { replaceWorkFilter } from './account/services/service-panel/service-detail/replace-work.filter.js';
import { $servicePanelStatusService } from './account/services/service-panel/service-detail/service-panel-status.service.js';
import { $serviceStatusWithBucketNameService } from './account/services/projects/project-history/service-status-with-bucket-name.service.js';
import { TaskBucketFactory } from './account/dashboard/tasks/task-bucket.factory.js';
import { CalculateWorkOrderTimeFactory } from './account/dashboard/tasks/calculate-work-order-time.factory.js';
import { CalculateTimeFactory } from './account/dashboard/tasks/calculate-time.factory.js';
import { TaskBucketArchivedFactory } from './account/dashboard/tasks/task-bucket-archived.factory.js';
import { TaskBucketUnresolvedFactory } from './account/dashboard/tasks/task-bucket-unresolved.factory.js';
import { TaskBucketResolvedFactory } from './account/dashboard/tasks/task-bucket-resolved.factory.js';
import { TaskBucketPendingFactory } from './account/dashboard/tasks/task-bucket-pending.factory.js';
import { AllTasksBucketFactory } from './account/dashboard/tasks/all-tasks-bucket.factory.js';
import { BucketFactory } from './account/dashboard/tasks/bucket.factory.js';
import { TaskFactory } from './account/dashboard/tasks/task.factory.js';
import { timezoneDateFilter } from './account/dashboard/tasks/timezone-date.filter.js';
import { priorityHumanFilter } from './account/dashboard/tasks/priority-human.filter.js';
import { tillFilter } from './account/dashboard/tasks/till.filter.js';
import { capitalizeFilter } from './account/dashboard/tasks/capitalize.filter.js';
import { rangeFilter } from './account/dashboard/tasks/range.filter.js';
import { taskMapDirective } from './account/dashboard/tasks/task-map.directive.js';
import { fileDropzoneDirective } from './account/dashboard/tasks/file-dropzone.directive.js';
import { $woStatusService } from './account/dashboard/tasks/wo-status.service.js';
import { $getECOITimezoneOffsetService } from './account/dashboard/tasks/get-ecoitimezone-offset.service.js';
import { $convertSecondToTimeService } from './account/dashboard/tasks/convert-second-to-time.service.js';
import { $performTaskScoreService } from './account/dashboard/tasks/perform-task-score.service.js';
import { $performTaskAudioService } from './account/dashboard/tasks/perform-task-audio.service.js';
import { $performTaskTranscribeAudioService } from './account/dashboard/tasks/perform-task-transcribe-audio.service.js';
import { $performSurveyTaskCreateService } from './account/dashboard/tasks/perform-survey-task-create.service.js';
import { $performToggleStateService } from './account/dashboard/tasks/perform-toggle-state.service.js';
import { $performDueUpdateService } from './account/dashboard/tasks/perform-due-update.service.js';
import { $performForwardTaskEmailService } from './account/dashboard/tasks/perform-forward-task-email.service.js';
import { $performTaskUpdateService } from './account/dashboard/tasks/perform-task-update.service.js';
import { $performTaskBucketSelectService } from './account/dashboard/tasks/perform-task-bucket-select.service.js';
import { $performTaskRemoveService } from './account/dashboard/tasks/perform-task-remove.service.js';
import { $performTaskReopenService } from './account/dashboard/tasks/perform-task-reopen.service.js';
import { $performTaskAssignService } from './account/dashboard/tasks/perform-task-assign.service.js';
import { $performTaskRejectService } from './account/dashboard/tasks/perform-task-reject.service.js';
import { $performTaskArchiveService } from './account/dashboard/tasks/perform-task-archive.service.js';
import { $performTaskResolveService } from './account/dashboard/tasks/perform-task-resolve.service.js';
import { $performTaskCreateService } from './account/dashboard/tasks/perform-task-create.service.js';
import { $performTaskHistoryService } from './account/dashboard/tasks/perform-task-history.service.js';
import { $performTaskResolveHistoryService } from './account/dashboard/tasks/perform-task-resolve-history.service.js';
import { sentimentFilter } from './account/dashboard/tasks/tabs/organization/performance/scores/sentiment.filter.js';
import { scoresTableDirective } from './account/dashboard/tasks/tabs/organization/performance/scores/scores-table.directive.js';
import { commentsTableDirective } from './account/dashboard/tasks/tabs/organization/performance/comments/comments-table.directive.js';
import { $stateFilterService } from './account/dashboard/office-operations/state-filter.service.js';
import { $judgeInOutValueService } from './account/dashboard/office-operations/judge-in-out-value.service.js';
import { $officeOperationColumnFilterService } from './account/dashboard/office-operations/office-operation-column-filter.service.js';
import { courttimeDirective } from './account/dashboard/main-dashboard/work-orders/tabs/wo-forecasting/courttime.directive.js';
import { $getImageFilePathService } from './account/dashboard/main-dashboard/sosi-one-reporting/tabs/interpreter-matrix/get-image-file-path.service.js';
import { $printDateService } from './account/dashboard/main-dashboard/sosi-one-reporting/tabs/interpreter-matrix/print-date.service.js';
import { cstDateFilter } from './account/dashboard/main-dashboard/sosi-one-reporting/tabs/dq-report/cst-date.filter.js';
import { languageArrayFilterByPrintFilter } from './account/dashboard/main-dashboard/pipline-operations/tabs/pipline/language-array-filter-by-print.filter.js';
import { languageArrayFilter } from './account/dashboard/main-dashboard/pipline-operations/tabs/pipline/language-array.filter.js';
import { tooltipDirective } from './account/dashboard/main-dashboard/pipline-operations/tabs/pipline/tooltip.directive.js';
import { $assessmentYearsService } from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/credentials/assessment-years.service.js';
import { $degreeListService } from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/credentials/degree-list.service.js';
import { $degreeOrCertificateTypeService } from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/credentials/degree-or-certificate-type.service.js';
import { $assessmentPartsService } from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/credentials/assessment-parts.service.js';
import { $assessmentTypesService } from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/credentials/assessment-types.service.js';
import { labourCategoryFilter } from './account/dashboard/main-dashboard/interpreter-resource/tabs/profile/labour-category.filter.js';
import { $getTZTimeListService } from './account/dashboard/main-dashboard/interpreter-resource/tabs/open-work-orders/get-tztime-list.service.js';
import { $autoTimezoneFormatService } from './account/dashboard/main-dashboard/interpreter-resource/tabs/open-work-orders/auto-timezone-format.service.js';
import { selectionAvailabilityFilter } from './account/dashboard/main-dashboard/interpreter-resource/tabs/open-work-orders/interpreter-availability/selection-availability.filter.js';
import { $getWOTaskCommonService } from './account/dashboard/main-dashboard/interpreter-resource/tabs/interpreter-home/get-wotask-common.service.js';
import { $earlyReleaseFOUTService } from './account/dashboard/kiosk/early-release-fout.service.js';
import { $checkBusinessHoursService } from './account/dashboard/kiosk/check-business-hours.service.js';
import { $lateTimeService } from './account/dashboard/kiosk/late-time.service.js';
import { $earlyFINOrNotService } from './account/dashboard/kiosk/early-finor-not.service.js';

import { uasModelExternalDirective } from './../components/util/uas-model-external.directive.js';
import { uasModelInternalDirective } from './../components/util/uas-model-internal.directive.js';
import { uasTaskDirective } from './../components/util/uas-task.directive.js';
import { uasExternalUsersDirective } from './../components/util/uas-external-users.directive.js';
import { uasUsersDirective } from './../components/util/uas-users.directive.js';
import { LoadSpinnerCtrlFactory } from './../components/util/load-spinner-ctrl.factory.js';
import { formErrorInputDirective } from './../components/util/form-error-input.directive.js';
import { formErrorMessagesDirective } from './../components/util/form-error-messages.directive.js';
import { draggableTriggerDirective } from './../components/util/draggable-trigger.directive.js';
import { onFinishRenderDirective } from './../components/forms/on-finish-render.directive.js';
import { $sessionExpireCheckService } from './../components/auth/session-expire-check.service.js';
import { $authStorageService } from './../components/auth/auth-storage.service.js';
import { AuthorizorFactory } from './../components/auth/authorizor.factory.js';
import { UserFactory } from './../components/api/user.factory.js';
import { $forgotPasswordService } from './../components/api/forgot_password/forgot-password.service.js';
import { ForgotPasswordFactory } from './../components/api/forgot_password/forgot-password.factory.js';
import { DatabaseFactory } from './../components/api/database.factory.js';
import { $WOForecastingService } from './../components/api/client/woforecasting.service.js';
import { WOForecastingFactory } from './../components/api/client/woforecasting.factory.js';
import { $clientUserService } from './../components/api/client/client-user.service.js';
import { ClientUserFactory } from './../components/api/client/client-user.factory.js';
import { UserNotificationFactory } from './../components/api/client/user-notification.factory.js';
import { $uploadS3AttachmentService } from './../components/api/client/upload-s3-attachment.service.js';
import { $clientUploadService } from './../components/api/client/client-upload.service.js';
import { ClientUploadFactory } from './../components/api/client/client-upload.factory.js';
import { $clientTrainingManagerService } from './../components/api/client/client-training-manager.service.js';
import { ClientTrainingManagerFactory } from './../components/api/client/client-training-manager.factory.js';
import { $clientTimeProductionReportingService } from './../components/api/client/client-time-production-reporting.service.js';
import { ClientTimeProductionReportingFactory } from './../components/api/client/client-time-production-reporting.factory.js';
import { $clientTaskService } from './../components/api/client/client-task.service.js';
import { ClientTaskFactory } from './../components/api/client/client-task.factory.js';
import { $taskActionsLogsManagerService } from './../components/api/client/task-actions-logs-manager.service.js';
import { TaskActionsLogsManagerFactory } from './../components/api/client/task-actions-logs-manager.factory.js';
import { $taskCreateRulesService } from './../components/api/client/task-create-rules.service.js';
import { TaskCreateRulesFactory } from './../components/api/client/task-create-rules.factory.js';
import { $tablesManagerService } from './../components/api/client/tables-manager.service.js';
import { ClientTableFactory } from './../components/api/client/client-table.factory.js';
import { $clientSystemLogsService } from './../components/api/client/client-system-logs.service.js';
import { ClientSystemLogsFactory } from './../components/api/client/client-system-logs.factory.js';
import { $clientSurveyService } from './../components/api/client/client-survey.service.js';
import { ClientSurveyFactory } from './../components/api/client/client-survey.factory.js';
import { $serveySentimentService } from './../components/api/client/servey-sentiment.service.js';
import { SurveySentimentFactory } from './../components/api/client/survey-sentiment.factory.js';
import { $clientSurveyQuestionService } from './../components/api/client/client-survey-question.service.js';
import { ClientSurveyQuestionFactory } from './../components/api/client/client-survey-question.factory.js';
import { $clientSubClientService } from './../components/api/client/client-sub-client.service.js';
import { ClientSubClientFactory } from './../components/api/client/client-sub-client.factory.js';
import { SosnotsFactory } from './../components/api/client/sosnots.factory.js';
import { $sosEmailNotificationService } from './../components/api/client/sos-email-notification.service.js';
import { SOSEmailNotificationFactory } from './../components/api/client/sosemail-notification.factory.js';
import { $activeClientSettingsService } from './../components/api/client/active-client-settings.service.js';
import { $clientSettingService } from './../components/api/client/client-setting.service.js';
import { ClientSettingFactory } from './../components/api/client/client-setting.factory.js';
import { $serviceJobInvoicesService } from './../components/api/client/service-job-invoices.service.js';
import { serviceJobInvoicesFactory } from './../components/api/client/service-job-invoices.factory.js';
import { ServicePortalFactory } from './../components/api/client/service-portal.factory.js';
import { servicePanelFactory } from './../components/api/client/service-panel.factory.js';
import { serviceCommentsFactory } from './../components/api/client/service-comments.factory.js';
import { $ServiceProjectCommentService } from './../components/api/client/service-project-comment.service.js';
import { ServiceProjectCommentFactory } from './../components/api/client/service-project-comment.factory.js';
import { $scheduleTasksManagerService } from './../components/api/client/schedule-tasks-manager.service.js';
import { ScheduleTasksManagerFactory } from './../components/api/client/schedule-tasks-manager.factory.js';
import { $rulesLogsManagerService } from './../components/api/client/rules-logs-manager.service.js';
import { RulesLogsManagerFactory } from './../components/api/client/rules-logs-manager.factory.js';
import { $rulesExecutionLogsManagerService } from './../components/api/client/rules-execution-logs-manager.service.js';
import { RulesExecutionLogsManagerFactory } from './../components/api/client/rules-execution-logs-manager.factory.js';
import { $clientRolesService } from './../components/api/client/client-roles.service.js';
import { ClientRolesFactory } from './../components/api/client/client-roles.factory.js';
import { $clientResolveCodesService } from './../components/api/client/client-resolve-codes.service.js';
import { ClientResolveCodesFactory } from './../components/api/client/client-resolve-codes.factory.js';
import { $clientRequestInterpreterService } from './../components/api/client/client-request-interpreter.service.js';
import { ClientRequestInterpreterFactory } from './../components/api/client/client-request-interpreter.factory.js';
import { $taskReportsService } from './../components/api/client/task-reports.service.js';
import { TaskReportsManagerFactory } from './../components/api/client/task-reports-manager.factory.js';
import { $clientReportBucketsService } from './../components/api/client/client-report-buckets.service.js';
import { ClientReportBucketsFactory } from './../components/api/client/client-report-buckets.factory.js';
import { $clientReportBucketsUploadService } from './../components/api/client/client-report-buckets-upload.service.js';
import { ClientReportBucketsUploadFactory } from './../components/api/client/client-report-buckets-upload.factory.js';
import { $clientRegionService } from './../components/api/client/client-region.service.js';
import { ClientRegionFactory } from './../components/api/client/client-region.factory.js';
import { $clientQueryBuilderService } from './../components/api/client/client-query-builder.service.js';
import { ClientQueryBuilderFactory } from './../components/api/client/client-query-builder.factory.js';
import { $programsService } from './../components/api/client/programs.service.js';
import { ProgramsFactory } from './../components/api/client/programs.factory.js';
import { $pipelineOperationsService } from './../components/api/client/pipeline-operations.service.js';
import { PipelineOperationsFactory } from './../components/api/client/pipeline-operations.factory.js';
import { $clientPGQueryBuilderService } from './../components/api/client/client-pgquery-builder.service.js';
import { ClientPGQueryBuilderFactory } from './../components/api/client/client-pgquery-builder.factory.js';
import { $clientPermissionTypesService } from './../components/api/client/client-permission-types.service.js';
import { $clientPermissionsService } from './../components/api/client/client-permissions.service.js';
import { ClientPermissionsFactory } from './../components/api/client/client-permissions.factory.js';
import { $permissionMatrixService } from './../components/api/client/permission-matrix.service.js';
import { PermissionMatrixFactory } from './../components/api/client/permission-matrix.factory.js';
import { $patientContactsService } from './../components/api/client/patient-contacts.service.js';
import { PatientContactsFactory } from './../components/api/client/patient-contacts.factory.js';
import { $OtcAnyWhereService } from './../components/api/client/otc-any-where.service.js';
import { OtcAnyWhereFactory } from './../components/api/client/otc-any-where.factory.js';
import { OpenWorkOrdersFactory } from './../components/api/client/open-work-orders.factory.js';
import { $clientOfficeService } from './../components/api/client/client-office.service.js';
import { ClientOfficeFactory } from './../components/api/client/client-office.factory.js';
import { $printCoiCotiService } from './../components/api/client/print-coi-coti.service.js';
import { $officeOperationsService } from './../components/api/client/office-operations.service.js';
import { OfficeOperationsFactory } from './../components/api/client/office-operations.factory.js';
import { $clientOfficeInformationService } from './../components/api/client/client-office-information.service.js';
import { ClientOfficeInformationFactory } from './../components/api/client/client-office-information.factory.js';
import { OfferingRulesFactory } from './../components/api/client/offering-rules.factory.js';
import { $clientUserNotificationsService } from './../components/api/client/client-user-notifications.service.js';
import { ClientUserNotificationsFactory } from './../components/api/client/client-user-notifications.factory.js';
import { $notificationManagementService } from './../components/api/client/notification-management.service.js';
import { NotificationManagementFactory } from './../components/api/client/notification-management.factory.js';
import { $clientNotificationLogsService } from './../components/api/client/client-notification-logs.service.js';
import { ClientNotificationLogsFactory } from './../components/api/client/client-notification-logs.factory.js';
import { NationalitiesFactory } from './../components/api/client/nationalities.factory.js';
import { $monthlyUserAccessLogsService } from './../components/api/client/monthly-user-access-logs.service.js';
import { MonthlyUserAccessLogsFactory } from './../components/api/client/monthly-user-access-logs.factory.js';
import { $mobileAppManagerService } from './../components/api/client/mobile-app-manager.service.js';
import { MobileAppManagerFactory } from './../components/api/client/mobile-app-manager.factory.js';
import { $clientManagerService } from './../components/api/client/client-manager.service.js';
import { ClientManagerFactory } from './../components/api/client/client-manager.factory.js';
import { $liveReportsService } from './../components/api/client/live-reports.service.js';
import { LiveReportsFactory } from './../components/api/client/live-reports.factory.js';
import { ListsFactory } from './../components/api/client/lists.factory.js';
import { LinguistFactory } from './../components/api/client/linguist.factory.js';
import { $languageTypesService } from './../components/api/client/language-types.service.js';
import { $clientLanguagesService } from './../components/api/client/client-languages.service.js';
import { $clientKnowledgeLibraryService } from './../components/api/client/client-knowledge-library.service.js';
import { ClientKnowledgeLibraryFactory } from './../components/api/client/client-knowledge-library.factory.js';
import { JudgesFactory } from './../components/api/client/judges.factory.js';
import { $syncHistoryService } from './../components/api/client/sync-history.service.js';
import { SyncHistoryFactory } from './../components/api/client/sync-history.factory.js';
import { InvoiceFactory } from './../components/api/client/invoice.factory.js';
import { $clientInventoryService } from './../components/api/client/client-inventory.service.js';
import { ClientInventoryFactory } from './../components/api/client/client-inventory.factory.js';
import { $intLangsService } from './../components/api/client/int-langs.service.js';
import { IntLangsFactory } from './../components/api/client/int-langs.factory.js';
import { $clientInterpreterService } from './../components/api/client/client-interpreter.service.js';
import { ClientInterpreterFactory } from './../components/api/client/client-interpreter.factory.js';
import { InterpreterScheduledNTFactory } from './../components/api/client/interpreter-scheduled-nt.factory.js';
import { $clientInterpreterResourcesService } from './../components/api/client/client-interpreter-resources.service.js';
import { ClientInterpreterResourcesFactory } from './../components/api/client/client-interpreter-resources.factory.js';
import { $clientInterpreterProfileService } from './../components/api/client/client-interpreter-profile.service.js';
import { ClientInterpreterProfileFactory } from './../components/api/client/client-interpreter-profile.factory.js';
import { interpreterDocumentFactory } from './../components/api/client/interpreter-document.factory.js';
import { $clientInterpreterCourtDistanceService } from './../components/api/client/client-interpreter-court-distance.service.js';
import { ClientInterpreterCourtDistanceFactory } from './../components/api/client/client-interpreter-court-distance.factory.js';
import { $interpretersWOAvailabilityService } from './../components/api/client/interpreters-woavailability.service.js';
import { InterpretersWOAvailabilityFactory } from './../components/api/client/interpreters-woavailability.factory.js';
import { InterpreterAssessmentsFactory } from './../components/api/client/interpreter-assessments.factory.js';
import { $interpreterMatrixService } from './../components/api/client/interpreter-matrix.service.js';
import { InterpreterMatrixFactory } from './../components/api/client/interpreter-matrix.factory.js';
import { $icaTrackingService } from './../components/api/client/ica-tracking.service.js';
import { icaTrackingFactory } from './../components/api/client/ica-tracking.factory.js';
import { $icDegreesOrCertificatesService } from './../components/api/client/ic-degrees-or-certificates.service.js';
import { IcDegreesOrCertificatesFactory } from './../components/api/client/ic-degrees-or-certificates.factory.js';
import { $clientHolidaysService } from './../components/api/client/client-holidays.service.js';
import { ClientHolidaysFactory } from './../components/api/client/client-holidays.factory.js';
import { $mobileAppService } from './../components/api/client/mobile-app.service.js';
import { MobileAppFactory } from './../components/api/client/mobile-app.factory.js';
import { $fundingEventsService } from './../components/api/client/funding-events.service.js';
import { FunddingEventsFactory } from './../components/api/client/fundding-events.factory.js';
import { $clientFeatureService } from './../components/api/client/client-feature.service.js';
import { ClientFeatureFactory } from './../components/api/client/client-feature.factory.js';
import { $externalTaskService } from './../components/api/client/external-task.service.js';
import { ExternalTaskFactory } from './../components/api/client/external-task.factory.js';
import { $clientExternalUsersService } from './../components/api/client/client-external-users.service.js';
import { ClientExternalUsersFactory } from './../components/api/client/client-external-users.factory.js';
import { $clientDeviceEventsManagerService } from './../components/api/client/client-device-events-manager.service.js';
import { ClientDeviceEventsManagerFactory } from './../components/api/client/client-device-events-manager.factory.js';
import { $clientEquipmentService } from './../components/api/client/client-equipment.service.js';
import { ClientEquipmentFactory } from './../components/api/client/client-equipment.factory.js';
import { $dqsReportsService } from './../components/api/client/dqs-reports.service.js';
import { DQSReportsFactory } from './../components/api/client/dqsreports.factory.js';
import { $clientDivisionService } from './../components/api/client/client-division.service.js';
import { ClientDivisionFactory } from './../components/api/client/client-division.factory.js';
import { $devicesManagerService } from './../components/api/client/devices-manager.service.js';
import { DevicesManagerFactory } from './../components/api/client/devices-manager.factory.js';
import { $clientDailyWoReportsService } from './../components/api/client/client-daily-wo-reports.service.js';
import { ClientDailyWoReportsFactory } from './../components/api/client/client-daily-wo-reports.factory.js';
import { $clientCustomerService } from './../components/api/client/client-customer.service.js';
import { ClientCustomerFactory } from './../components/api/client/client-customer.factory.js';
import { CourtOperationsFactory } from './../components/api/client/court-operations.factory.js';
import { CoordinatorsFactory } from './../components/api/client/coordinators.factory.js';
import { $clientEmployeeService } from './../components/api/client/client-employee.service.js';
import { ClientEmployeeFactory } from './../components/api/client/client-employee.factory.js';
import { $clientContactCoordinateService } from './../components/api/client/client-contact-coordinate.service.js';
import { clientContactCoordinateFactory } from './../components/api/client/client-contact-coordinate.factory.js';
import { $clientCoiVRIInfoService } from './../components/api/client/client-coi-vriinfo.service.js';
import { ClientCoiVRIInfoFactory } from './../components/api/client/client-coi-vriinfo.factory.js';
import { $clientCOINotesService } from './../components/api/client/client-coinotes.service.js';
import { ClientCOINotesFactory } from './../components/api/client/client-coinotes.factory.js';
import { $cognitoUserService } from './../components/api/client/cognito-user.service.js';
import { $setGetCognitoUserService } from './../components/api/client/set-get-cognito-user.service.js';
import { $cognitoUserPoolService } from './../components/api/client/cognito-user-pool.service.js';
import { CasesFactory } from './../components/api/client/cases.factory.js';
import { $clientCampaignService } from './../components/api/client/client-campaign.service.js';
import { ClientCampaignFactory } from './../components/api/client/client-campaign.factory.js';
import { $clientBucketService } from './../components/api/client/client-bucket.service.js';
import { ClientBucketFactory } from './../components/api/client/client-bucket.factory.js';
import { AutoAssignedFactory } from './../components/api/client/auto-assigned.factory.js';
import { $downloadAttachmentService } from './../components/api/client/download-attachment.service.js';
import { $clientSwitcherService } from './../components/api/client-switcher.service.js';
import { $activeClientService } from './../components/api/active-client.service.js';
import { $clientSwitcherStorageService } from './../components/api/client-switcher-storage.service.js';
import { ClientFactory } from './../components/api/client.factory.js';
import { $clientUserSettingService } from './../components/api/client-user/client-user-setting.service.js';
import { ClientUserSettingFactory } from './../components/api/client-user/client-user-setting.factory.js';
// components
import {  NavbarCtrl  }  from '../components/navbar/navbar.controller.js';
import {  DraggableCtrl ,  draggableDirective ,   }  from '../components/util/draggable.directive.js';
import {  UasUsersCtrl ,  UasExternalUsersCtrl ,  UasTaskCtrl ,  UasModelInternalCtrl ,  UasModelExternalCtrl ,  UasCtrl  , userAssignSelectorDirective  }  from '../components/util/user-assign-selector.directive.js';
import {  $clientService   }  from '../components/api/client.service.js';
import {  $attachmentService ,   }  from '../components/api/client/attachment.service.js';
import {  $autoAssignedService ,   }  from '../components/api/client/auto-assigned.service.js';
import {  $casesService ,   }  from '../components/api/client/cases.service.js';
import {   $cognitoService  }  from '../components/api/client/cognito.service.js';
import {  $coordinatorsService ,   }  from '../components/api/client/coordinators.service.js';
import {  $courtOperationsService ,   }  from '../components/api/client/court-operations.service.js';
import {  $interpreterAssessmentsService ,   }  from '../components/api/client/interpreter-assessments.service.js';
import {  $interpreterDocumentService ,   }  from '../components/api/client/interpreter-document.service.js';
import {  $interpreterScheduledNTService ,   }  from '../components/api/client/interpreter-scheduled-nt.service.js';
import {  $invoiceService ,   }  from '../components/api/client/invoice.service.js';
import {  $judgesService ,   }  from '../components/api/client/judges.service.js';
import {  ClientLanguagesFactory,  }  from '../components/api/client/client-languages.factory.js';
import {  $LinguistService ,   }  from '../components/api/client/linguist.service.js';
import {  $listsService ,   }  from '../components/api/client/lists.service.js';
import {  $nationalitiesService ,   }  from '../components/api/client/nationalities.service.js';
import {  $offeringRulesService ,   }  from '../components/api/client/offering-rules.service.js';
import {  $openWorkOrdersService ,   }  from '../components/api/client/open-work-orders.service.js';
import {  $rulesManagerService ,  RulesManagerFactory  }  from '../components/api/client/rules.services.js';
import {  $ServiceUserProjectService ,  ServiceUserProjectFactory  }  from '../components/api/client/service_user_project.services.js';
import {  $serviceCommentsService ,   }  from '../components/api/client/service-comments.service.js';
import {  $servicePanelService ,   }  from '../components/api/client/service-panel.service.js';
import {  $ServicePortalService ,   }  from '../components/api/client/service-portal.service.js';
import {  $sosnotsService ,   }  from '../components/api/client/sosnots.service.js';
import {  $userNotificationService ,   }  from '../components/api/client/user-notification.service.js';
import {  $usersSessionService ,  UsersSessionFactory  }  from '../components/api/client/user-session.services.js';
import {  $databaseService ,   }  from '../components/api/database.service.js';
import {  $userService ,   }  from '../components/api/user.service.js';
import {  $authService    }  from '../components/auth/auth.service.js';
import {  $statesService   }  from '../components/forms/state.service.js';
import {  $defaultDateRangFilterService ,  formReportBucketDirective  }  from '../components/forms/report-bucket-directive.js';
import {  $colorUtilService  }  from '../components/util/colors.service.js';
import {  $convertDateService  }  from '../components/util/convert-date.service.js';
import {  $inheritService  }  from '../components/util/inherit.service.js';
import {  $metaInformationService  }  from '../components/util/meta-information.js';
import {  $pageTitleService  }  from '../components/util/page-title.service.js';
import {  $randomlyService  }  from '../components/util/randomly.service.js';
import {  $stringService  }  from '../components/util/string-utils.service.js';
import {  $dateValueService ,  validateDateDirective  }  from '../components/util/validate-date.js';
import {  audioPlayerDirective ,  audioPlayerControlsDirective ,  audioDurationDirective ,  AudioTrackFactory ,  AudioPlayListFactory  }  from '../components/audio/audio.directives.js';
import {  bucketActionsDirective  }  from '../components/bucket/action.directive.js';
import {  employeeTaskPerformanceDirective  }  from '../components/employee-task-performance-charts/employee-task-performance-charts.directive.js';
import {  featureTabsDirective  }  from '../components/feature/feature.directive.js';
import {  formBucketDirective  }  from '../components/forms/bucket.directive.js';
import {  formCampaignDirective  }  from '../components/forms/campaign.directive.js';
import {  formCaptioningDirective  }  from '../components/forms/captioning.directive.js';
import {  createRuleDirective  }  from '../components/forms/create-rule.directive.js';
import {  formCustomerDirective  }  from '../components/forms/customer.directive.js';
import {  formDivisionDirective  }  from '../components/forms/division.directive.js';
import {  formEmployeeDirective  }  from '../components/forms/employees.directive.js';
import {  formEquipmentDirective  }  from '../components/forms/equipment.directive.js';
import {  formInventoryDirective  }  from '../components/forms/inventory.directive.js';
import {  formJudgeDirective  }  from '../components/forms/judge.directive.js';
import {  formLanguagesDirective  }  from '../components/forms/languages.directive.js';
import {  formLinguistUserDirective  }  from '../components/forms/linguist-user.directive.js';
import {  formLocalizationDirective  }  from '../components/forms/localization.directive.js';
import {  formManagerEmployeesDirective  }  from '../components/forms/manager-employees.directive.js';
import {  formManagerDirective  }  from '../components/forms/manager.directive.js';
import {  formMobileAppDirective  }  from '../components/forms/mobile-app.directive.js';
import {  formNationalityDirective  }  from '../components/forms/nationality.directive.js';
import {  offeringRuleDirective  }  from '../components/forms/offering-rule.directive.js';
import {  formOnSiteInterpretationDirective  }  from '../components/forms/on-site-interpreation.directive.js';
import {  formPermissionsDirective  }  from '../components/forms/permissions.directive.js';
import {  programDirective  }  from '../components/forms/program.directive.js';
import {  formRegionDirective  }  from '../components/forms/region.directive.js';
import {  formResolveCodeDirective  }  from '../components/forms/resolve-code-directive.js';
import {  formRolesDirective  }  from '../components/forms/roles.directive.js';
import {  scheduleTaskDirective  }  from '../components/forms/schedule-task.js';
import {  formServiceUserDirective  }  from '../components/forms/service-user.directive.js';
import {  starRatingDirective  }  from '../components/forms/starApp.js';
import {  formSubClientDirective  }  from '../components/forms/sub-client.directive.js';
import {  sentimentDirective  }  from '../components/forms/survey/sentiment.directive.js';
import {  surveyQuestionDirective  }  from '../components/forms/survey/survey_question.directive.js';
import {  formSurveyTaskDirective  }  from '../components/forms/survey/task.js';
import {  clientTableDirective  }  from '../components/forms/table.directive.js';
import {  taskCreateRuleDirective  }  from '../components/forms/task-create-rule.directive.js';
import {  formTaskDirective  }  from '../components/forms/task.directive.js';
import {  formTelephonicInterpretationDirective  }  from '../components/forms/telephonic-interpretation.directive.js';
import {  formTranscriptionDirective  }  from '../components/forms/transcription.directive.js';
import { projectHistoryDirective} from './account/services/projects/project-history/project-history.directive.js';
import { serviceDetailViewDirective } from './account/services/service-panel/service-detail/service-detail-view.directive.js';
import {  formTranslationDirective  }  from '../components/forms/translation.directive.js';
import {  formUserDirective  }  from '../components/forms/user.directive.js';
import {  gridCellDirective ,  gridDirective ,  CollectionRecordFactory ,  CollectionColumnFactory ,  CollectionFactory  }  from '../components/grid/grid.js';
import {  mongooseErrorDirective  }  from '../components/mongoose-error/mongoose-error.directive.js';
import {  navbarItemDirective  }  from '../components/navbar/navbar.directive.js';
import {  ngDropdownMultiselectDirective  }  from '../components/ngDropdownMultiselect/ngDropdownMultiselect.directive.js';
import {  notificationSettingsDirective  }  from '../components/notification-settings/notification-settings.directive.js';
import {  searchSelectPaginationDirective  }  from '../components/search-select-pagination/search-select-pagination.directive.js';
import {  selectAndSearchDirective  }  from '../components/select-and-search/select-and-search.directive.js';
import {  showAttachmentsDirective  }  from '../components/show-attachments/show-attachments.directive.js';
import {  taskCalendarDirective  }  from '../components/task-calendar/task-calendar.directive.js';
import {  surveyTaskControlsDirective  }  from '../components/task-controls/survey/task-controls.js';
import {  taskControlsDirective  }  from '../components/task-controls/task-controls.js';
import {  myToggleSwitchDirective  }  from '../components/toggle-switch/toggle-switch.directive.js';
import {  uploadFormS3Directive  }  from '../components/upload-s3/upload-s3.directive.js';
import {  uploadFormDirective  }  from '../components/upload/upload.directive.js';
import {  formErrorsDirective    }  from '../components/util/form-errors.directive.js';
import {  loadSpinnerDirective   }  from '../components/util/load-spinner.directive.js';
import {  mustMatchDirective  }  from '../components/util/must-match.directive.js';
import {  numericonlyDirective  }  from '../components/util/number-only.directive.js';
import {  presetTimeDirective  }  from '../components/util/preset-time.directive.js';
import {  ruleWindowDirective  }  from '../components/util/rule-window.directive.js';
import {  ClientContactFactory  }  from '../components/api/client/contact.service.js';
import {  ModalFactory  }  from '../components/modal/modal.service.js';
import {  SurveyModalFactory  }  from '../components/modal/survey/modal.service.js';
import {  fileSizeFilter  }  from '../components/util/bytesize.filter.js';
import {  durationMSFilter  }  from '../components/util/duration.filter.js';
import {  percentageFilter  }  from '../components/util/percentage.filter.js';
import {  numberFormatFilter  }  from '../components/util/strings.filter.js';
import {  myTimeFilter  }  from '../components/util/time.filter.js';
import {  yesNoFilter  }  from '../components/util/yes-no.filter.js';
import { suppressionListConfig } from './account/dashboard/settings/suppression-list/suppression-list.js'
import { SuppressionListCtrl } from './account/dashboard/settings/suppression-list/suppression-list.controller.js'
import {AwsService,   $awsService} from '../components/api/client/elisa-aws.service.js'

// import {taskStatsDirective} from '../components/charts/task-stats.directive.js'




angular.module('coordinateApp', [
  'oc.lazyLoad',
  'ngRoute',
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'ngMessages',
  'ui.router',
  'ui.bootstrap',
  'rt.eventemitter',
  'angularFileUpload',
  'angular-utilities',
  'mediaPlayer',
  'ngMap',
  'ngToast',
  'ui.select',
  'ui-rangeSlider',
  'toggle-switch',
  // 'angularMoment',
  'ngTable',
  'ui.sortable',
  'ui.tree',
  'ui.utils.masks',
  'idf.br-filters',
  'ngIdle',
  // 'ngSentry',
  'dndLists',
  'btford.socket-io',
  'configENV'
])
.constant('TASK_STATE_PAUSED', 'paused')
.constant('TASK_STATE_STARTED', 'started')
.constant('TASK_STATE_COMPLETED', 'completed')
.constant('TASK_SCHEMA_TYPE_NORMAL', 'normal')
.constant('TASK_SCHEMA_TYPE_SURVEY', 'survey')
.constant('TASK_SEARCH_CONFIG_KEY', 'task-filter')
.constant('TASK_SCHEMA_TYPE_CASE', 'case')
.constant('IDLE_SCREEN_CONFIG', 'idle-config')
.constant('TASK_STATE_CHECKEDIN', 'Facility IN')
.constant('TASK_STATE_CHECKEDOUT', 'Facility OUT')
.constant('TASK_STATE_JUDGEDIN', 'Judge IN')
.constant('TASK_STATE_JUDGEDOUT', 'Judge OUT')
.constant('TASK_STATE_LUNCHIN', 'Lunch IN')
.constant('TASK_STATE_LUNCHOUT', 'Lunch OUT')
.constant('TASK_STATE_EXTEND', 'Extend')
.constant('TASK_STATE_RELEASE', 'Release')
.constant('TASK_STATE_RELEASE_EVENT', 'Release Event')
.constant('TASK_STATE_DENY_FOUT', 'Deny Facility OUT')
.constant('COURT_OPS_SEARCH_CONFIG_KEY', 'court-ops-filter')
.constant('WO_CONTROLS', 'WO_CONTROLS')
.constant('WO_PERFORMANCE', 'WO_PERFORMANCE')
.constant('TASK_STATE_IC_RELEASE_REQUEST', 'Interpreter Requests Release')
.constant('OSI', 'OSI')
.constant('OPI', 'OPI')
.constant('VRI', 'VRI')
.constant('INTERPRETER_DISPUTE', 'Interpreter Dispute')
.constant('COURT_DECLINE', 'Court Decline')
.constant('SOSI_DISPUTE', 'SOSI Dispute')
.constant('SOSI_ACCEPTED', 'SOSI Accepted')
.constant('COURT_APPROVE', 'Court Approved')
.constant('INTERPRETER_APPROVE', 'Interpreter Approved')
.constant('TASK_FLAG_EVENT_IC_ADDED_WO_EVENT', 'Interpreter Added WO event')
.constant('TASK_FLAG_EVENT_EARLY_RELEASE', 'Early Release')
.constant('WO_TASK_OPI_FIN_REQUESTED', 'OPI FIN Requested')
.constant('WO_TASK_VRI_FIN_REQUESTED', 'VRI FIN Requested')
.constant('WO_TASK_PENDING_SOSI_APPROVAL', 'Pending SOSI Approval')
.constant('TASK_STATE_RELEASE_APPROVED', 'Release Endorsed')
.constant('TASK_STATE_OPI_FIN_PENDING_APPROVAL', 'OPI FIN Pending Approval')
.constant('TASK_STATE_VRI_FIN_PENDING_APPROVAL', 'VRI FIN Pending Approval')
.constant('MY_RESOURCES', 'MY_RESOURCES')
.constant('CLIENT_ID', '5b6b5157e71b901f303028ce')
.constant('DEFAULT_DEBOUNCE', 2000)
.constant('idleTime', 3600)
.constant('idleTimeout', 1800)
.constant('extendTime', 3600)
.constant('WO_Event', 'WO Event')
.constant('System_Event', 'System Event')
.constant('Service_Portal_Event', 'Service Portal Event')
.constant('Service_Event', 'Service Event')
.constant('uiDatetimePickerConfig', {
  dateFormat: 'yyyy-MM-dd hh:mm a',
  defaultTime: '00:00:00',
  html5Types: {
      date: 'yyyy-MM-dd',
      'datetime-local': 'yyyy-MM-ddTHH:mm:ss.sss',
      'month': 'yyyy-MM'
  },
  initialPicker: 'date',
  reOpenDefault: false,
  enableDate: true,
  enableTime: true,
  buttonBar: {
      show: true,
      now: {
          show: false,
          text: 'Now',
          cls: 'btn-sm btn-default'
      },
      today: {
          show: false,
          text: 'Today',
          cls: 'btn-sm btn-default'
      },
      clear: {
          show: true,
          text: 'Clear',
          cls: 'btn-sm btn-default'
      },
      date: {
          show: true,
          text: 'Date',
          cls: 'btn-sm btn-default'
      },
      time: {
          show: true,
          text: 'Time',
          cls: 'btn-sm btn-default'
      },
      close: {
          show: true,
          text: 'Close',
          cls: 'btn-sm btn-default'
      },
      cancel: {
          show: false,
          text: 'Cancel',
          cls: 'btn-sm btn-default'
      }
  },
  closeOnDateSelection: true,
  closeOnTimeNow: true,
  // appendToBody: true,
  altInputFormats: [],
  ngModelOptions: {},
  saveAs: false,
  readAs: false
})
.run(['envConfig', function(envConfig) {
  if (envConfig.env !== 'development') {
    Sentry.init({
      dsn: 'https://a8703a85239b49ed88033c6e5ad7c847@o223672.ingest.sentry.io/1541818',
      integrations: [
        new Sentry.Integrations.Angular(),
      ],
    });
  }
}])
.run(['$ocLazyLoad', function($ocLazyLoad) {
  // Dynamically import moment, moment-timezone, and angular-moment
  import('moment').then(({ default: moment }) => {
      window.moment = moment;
      return import('moment-timezone/builds/moment-timezone-with-data-10-year-range.min');
  }).then(() => {
      return import('angular-moment');
  }).then(() => {
      // Register angular-moment with the app
      $ocLazyLoad.load({
          name: 'angularMoment'
      }).then(() => {
          console.log('angularMoment loaded and registered');
      });
  }).catch(error => console.error('Error loading angularMoment:', error));
  Promise.all([
import( "fullcalendar/dist/fullcalendar.css"),
import( "angular-bootstrap-calendar/dist/css/angular-bootstrap-calendar.css"),
import( "bootstrap-datepicker/dist/css/bootstrap-datepicker3.css"),
import( "../bower_components/daterangepicker/dist/daterangepicker.css")
]).then()


  Promise.all([
    import('angular-bootstrap-calendar'),
    import('bootstrap-ui-datetime-picker'),
    import('gm.datepicker-multi-select'),
    import('../bower_components/daterangepicker/dist/daterangepicker.js'),
    import('../bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker.js')
]).then(modules=>{
    return Promise.all([$ocLazyLoad.load({
          name: 'gm.datepickerMultiSelect',}),
    $ocLazyLoad.load({
          name: 'rgkevin.datetimeRangePicker'}),
    $ocLazyLoad.load({
          name: 'mwl.calendar'}),
    $ocLazyLoad.load({
          name: 'ui.bootstrap.datetimepicker',})])
          .then(()=>{
            console.log('ui-bootstrap moudles loaded ')
          })
  })
}])
// .run(['$rootScope', function($rootScope) {

//   // Dynamically import Modal services
//   Promise.all([
//       import('../components/modal/modal.service.js'),
//       import('../components/modal/survey/modal.service.js')
//   ]).then(([{ ModalFactory }, { SurveyModalFactory }]) => {
//       angular.module('coordinateApp')
//           .factory('Modal', ModalFactory)
//           .factory('SurveyModal', SurveyModalFactory);
//       console.log('Modal services loaded');
//   }).catch(error => console.error('Error loading Modal services:', error));
// }])
.config(function ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, $uibTooltipProvider) {

  $uibTooltipProvider.options({
    popupDelay: 500
  });

  // abstract state for all others to iniherit
  $stateProvider
    .state('app', {
      'abstract': true ,
      templateUrl: 'app/app.layout.html',
      resolve: {
        authorization: ['Authorizor', function (Authorizor) {
          return Authorizor();
        }]
      }
    });
    $locationProvider.html5Mode(true);
    $urlRouterProvider .otherwise('app');
    $httpProvider.interceptors.push('authInterceptor');
})
.factory('LazyLoadService', ['$ocLazyLoad', '$q', '$templateCache', '$timeout', function($ocLazyLoad, $q, $templateCache, $timeout) {
  var loadedDirectives = {};

  function isModuleLoaded(moduleName) {
    try {
      angular.module(moduleName);
      return true;
    } catch (e) {
      if (/nomod/.test(e.message)) {
        return false;
      }
      throw e;
    }
  }

  return {
      loadGoogleMaps: function() {
        if (!$ocLazyLoad.isLoaded('uiGmapgoogle-maps')) {
          return loadScript('https://maps.google.com/maps/api/js?key=AIzaSyBlb4TpCd8GpizDgj6fgDlgh3UZevW1iQM&libraries=places').then(function() {

          return $q(function(resolve, reject) {
              import('angular-google-maps')
                  .then(googleMapsModule => {
                      $ocLazyLoad.load({
                          name: 'uiGmapgoogle-maps'
                      }).then(() => {
                        resolve('Google Maps configured and loaded');
                      }).catch(error => {
                          console.error("Failed to load the Google Maps module:", error);
                          reject(error);
                      });
                  }).catch(error => {
                      console.error("Failed to import the Google Maps module:", error);
                      reject(error);
                  });
          });
        });
      } else{
        return Promise.resolve();
      }
    },
    loadTemplate: function(templatePath, cacheName) {
      return $q(function(resolve, reject) {
        if ($templateCache.get(cacheName)) {
          resolve($templateCache.get(cacheName));
        } else {
          import(`${templatePath}`)
            .then(template => {
              $templateCache.put(cacheName, template.default);
              resolve(template.default);
            })
            .catch(error => {
              console.error('Failed to import the template:', error);
              reject(error);
            });
        }
      });
    },
    loadController: function(controllerPath, controllerName) {
      return $q(function(resolve, reject) {
        import(`${controllerPath}`)
          .then(module => {
            $ocLazyLoad.load({name: 'coordinateApp'});
            resolve(module[controllerName]);
          })
          .catch(error => {
            console.error('Failed to import the controller:', error);
            reject(error);
          });
      });
    },

    loadDirective: function(directivePath, directiveName) {
      const name =  directiveName.replace(/Directive$/, '');
      const directiveImport = typeof directivePath === 'string'? import(
        `${directivePath}`
      ): directivePath;
      return $q(function(resolve, reject) {
        if (loadedDirectives[directiveName]) {
          resolve('already loaded');
        } else {
          directiveImport.then(module => {
              angular.module('coordinateApp').directive(name, module[directiveName]);
              $ocLazyLoad.load({name: 'coordinateApp'})
                .then(async () => {
                  loadedDirectives[directiveName] = true;
                  resolve(module[directiveName]);
                })
                .catch(error => {
                  console.error('Failed to load the directive:', error);
                  reject(error);
                });
            })
            .catch(error => {
              console.error('Failed to import the directive:', error);
              reject(error);
            });
        }
      });
    },

    loadChartLibraries: function() {
      if (isModuleLoaded('chart.js') && isModuleLoaded('angular-chart.js')) {
        return $q.resolve('Chart libraries already loaded');
      } else {
        return $q(function(resolve, reject) {
          import('chart.js')
            .then(() => import('angular-chart.js'))
            .then(() => {
              $ocLazyLoad.load({name: 'chart.js'})
                .then(() => {
                  angular.module('coordinateApp').requires.push('chart.js');
                  resolve('Chart libraries loaded');
                })
                .catch(error => {
                  console.error('Failed to load the chart libraries:', error);
                  reject(error);
                });
            })
            .catch(error => {
              console.error('Failed to import the chart libraries:', error);
              reject(error);
            });
        });
      }
    },
    loadMinicolors: function() {
      if (isModuleLoaded('minicolors')) {
        return $q.resolve('Minicolors already loaded');
      } else {
        return $q(function(resolve, reject) {
          import('@claviska/jquery-minicolors/jquery.minicolors.css')
            .then(() => import('@claviska/jquery-minicolors/jquery.minicolors.js'))
            .then(() => import('../bower_components/angular-minicolors/angular-minicolors.js'))
            .then(() => {
              $ocLazyLoad.load({ name: 'minicolors' })
                .then(() => {
                  angular.module('coordinateApp').requires.push('minicolors');
                  resolve('Minicolors loaded');
                })
                .catch(error => {
                  console.error('Failed to load Minicolors:', error);
                  reject(error);
                });
            })
            .catch(error => {
              console.error('Failed to import Minicolors:', error);
              reject(error);
            });
        });
      }
    }
  };
}])
.factory('socket', function (socketFactory, appConfig) {

    // let url = "wss://y22x42m5vfcmvdhk3izsfrfoou.appsync-realtime-api.us-east-1.amazonaws.com/graphql?header=eyJob3N0IjoieTIyeDQybTV2ZmNtdmRoazNpenNmcmZvb3UuYXBwc3luYy1hcGkudXMtZWFzdC0xLmFtYXpvbmF3cy5jb20iLCJ4LWFwaS1rZXkiOiJkYTItcm9tazd6emFhamFyNWV0bXNsbTQ0NHYyaHUifQ==&payload=e30="
    // const api_header = {
    //   host: "y22x42m5vfcmvdhk3izsfrfoou.appsync-api.us-east-1.amazonaws.com",
    //   'x-api-key': "da2-romk7zzaajar5etmslm444v2hu",
    // };

    // let ws = new WebSocket(url, ['graphql-ws'])
    // const listenChannel = (channelName) => {
    //   let listen = JSON.stringify({
    //     "id": "1", // change this to UUID
    //     "type": "start",
    //     "payload": {
    //       "variables": {
    //       },
    //       "extensions": {
    //         "authorization": api_header
    //       },
    //       "operationName": null,
    //       "data": JSON.stringify({ "query": `subscription {\n  subscribe(name: \"${channelName}\") {\n data \n }\n}\n` })
    //     }
    //   });
    //   console.log(`Starting listener for ${channelName}`);
    //   ws.send(listen)
    // }

    // ws.onopen = (e) => {
    //   console.log('Socket opened');
    //   ws.send(JSON.stringify({ type: "connection_init" }));
    //   listenChannel("robots");
    // };

    // ws.onmessage = (e) => {
    //   let data = JSON.parse(e.data);
    //   if (data.payload) {
    //     console.log('Msg received', data.payload.data);
    //   } else {
    //     console.log('Msg received', e.data);
    //   }
    // };

    // ws.onclose = (e) => { console.log('Socket closed', e); };
    // ws.onerror = (e) => { console.log('Socket error', e); };

    // return socketFactory({
    //   ioSocket: io.connect(url, { transports: ['graphql-ws'] })
    // });

    return socketFactory({
      ioSocket: connect(appConfig.apiUrl, { transports: ['websocket'] })
    });

})
.factory('authInterceptor', function ($rootScope, $q, $authStorage, $location, $injector) {
  return {
    // Add authorization token to headers
    request: function (config) {
      if (!navigator.onLine) {
        // Inform user and reject the request
        return $q.reject("You Are Currently Offline! Please Refresh or check your Internet Connection.");
      }
      config.headers = config && config.headers ? config.headers : {};
      if ($authStorage.hasToken()) {
        if(!config.headers.Authorization && !config.ignoreAuth){
          config.headers.Authorization = $authStorage.getToken();
        }
      }
      return config;
    },

    // Intercept 401s and redirect you to login
    responseError: function(response) {
      var AuthService = $injector.get('$auth');
      var state = $injector.get('$state');
      var AuthStorage = $injector.get('$authStorage');
      if (response.status == 401) {
          // if (AuthService.getCurrentUser() && AuthService.getCurrentUser()._id) {
          //   Sentry.setTag("userId", AuthService.getCurrentUser()._id);
          //   Sentry.setTag("api_status", '401');
          //   Sentry.setTag("type", 'auto');
          //   var absUrl = $location.absUrl();
          //   Sentry.captureException(new Error(`${AuthService.getCurrentUser().name} user logout time ${new Date()} ${absUrl}`));
          // }
          console.log('Logout Response Error', response.status)
          const isServicePortalUser = localStorage.getItem('isServicePortalUser')
          AuthService.logout()
            .then(() => {
              if (isServicePortalUser && JSON.parse(isServicePortalUser)) {
                state.go('app.account.services.signin');
              } else {
                state.go('app.account.login',{}, {reload: true});
                window.location.reload()
              }
            })
            .catch((err) => {
              AuthStorage.clearToken();
              let errMessage = err.message || err;
              if (err && err.data && err.data.message) errMessage = err.data.message;
              console.log(errMessage)
              if (isServicePortalUser && JSON.parse(isServicePortalUser)) {
                state.go('app.account.services.signin');
              } else {
                state.go('app.account.login',{}, {reload: true});
                window.location.reload()
              }
            })
          // if (response && response.data && response.data.message == 'Session Expired, Please Sign in Again') {
          //   window.location.reload();
          // }
          return $q.reject(response);
      } else if (response.status == 400) {
        return $q.reject(response.data);
      } else if (response.status <= 0) {
        if (!navigator.onLine) {
          $rootScope.$broadcast("ServerError", response.status);
          // console.log('Network error occurred. Please check your internet connection.');
          return $q.reject('You Are Currently Offline! Please Refresh or check your Internet Connection');
        } else {
          console.log(response)
          let e = 'Something went wrong! try after sometime!'
          return $q.reject(e);
        }
      }else {
        return $q.reject(response);
      }
    }
  };
})

.service('$timezones', function () {
  var timezones = [
    { label: "Atlantic Daylight Time", value: "ADT", offset: "-0300", timezone: "America/Puerto_Rico", tzName: "America/Puerto_Rico" },
    { label: "Atlantic Standard Time", value: "AST", offset: "-0400", timezone: "America/Puerto_Rico", tzName: "America/Puerto_Rico" },
    { label: "Alaska Daylight Time", value: "AKDT", offset: "-0800", timezone: "US/Alaska", tzName: "US/Alaska" },
    { label: "Alaska Standard Time", value: "AKST", offset: "-0900", timezone: "US/Alaska", tzName: "US/Alaska" },
    { label: "Eastern Daylight Time", value: "EDT", offset: "-0400", timezone: "EST5EDT", tzName: "America/New_York" },
    { label: "Central Daylight Time", value: "CDT", offset: "-0500", timezone: "CST6CDT", tzName: "America/Chicago" },
    { label: "Central Standard Time", value: "CST", offset: "-0600", timezone: "CST6CDT", tzName: "America/Chicago" },
    { label: "Chamorro Standard Time", value: "CHST", offset: "+1000", timezone: "CHST", tzName: "CHST" },
    { label: "Hawaii-Aleutian Standard Time", value: "HST", offset: "-1000", timezone: "HST", tzName: "US/Hawaii" },
    { label: "Hawaii-Aleutian Daylight Time", value: "HDT", offset: "-0900", timezone: "HDT", tzName: "US/Hawaii" },
    { label: "Mountain Standard Time", value: "MST", offset: "-0700", timezone: "MST7MDT", tzName: "America/Phoenix" },
    { label: "Mountain Daylight Time", value: "MDT", offset: "-0600", timezone: "MST7MDT", tzName: "America/Denver" },
    { label: "Pacific Daylight Time", value: "PDT", offset: "-0700", timezone: "PST8PDT", tzName: "America/Los_Angeles" },
    { label: "Pacific Standard Time", value: "PST", offset: "-0800", timezone: "PST8PDT", tzName: "America/Los_Angeles" },
    { label: "Eastern Standard Time", value: "EST", offset: "-0500", timezone: "EST5EDT", tzName: "America/New_York" },
    { label: "UTC", value: "UTC", offset: "+0000", timezone: "UTC" , tzName: "UTC"}];
  return timezones;
})

.service('$interpretationMethods', function () {
  var interpretationMethods = [
    { label: "OSI (On-Site Interpretation)", value: "osi" },
    { label: "OPI (Over-Phone Interpretation)", value: "opi" },
    { label: "VRI (Video Remote Interpretation)", value: "vri" }];
  return interpretationMethods;
})
.service('$fieldOfInterpretation', function () {
  var interpretationMethods = [
    { label: "Arts/Entertainment", value: "arts_entertainment" },
    { label: "Business", value: "business" },
    { label: "Education", value: "education" },
    { label: "Government", value: "government" },
    { label: "Legal", value: "legal" },
    { label: "Medical", value: "medical" },
    { label: "Other", value: "other" }
  ];
  return interpretationMethods;
})
.service('$removeAbility', function () {
  var removeAbility = ['client.tasks', 'client.training', 'client.TaskHistory', 'client.Reports', 'client.officeOperations', 'client.live-reports', 'client.interpreterResources', 'client.sosEmailInformation', 'client.permissionMatrix'];
  return removeAbility;
})
.service('$serviceType', function () {
  let serviceType = [
    {
      label: 'OSI',
      value: 'OSI'
    },
    {
      label: 'OPI',
      value: 'OPI'
    },
    {
      label: 'VRI',
      value: 'VRI'
    }
  ]
  return serviceType
})
.service('$hearingType', function () {
  let hearingType = [
    {
      label: 'Individual Calendar | IC',
      value: 'IC'
    },
    {
      label: 'Master Calendar | MC',
      value: 'MC'
    },
    {
      label: 'Bond Hearing | BH',
      value: 'BH'
    }
  ]
  return hearingType;
})
.service('$serviceRequired', function () {
  let serviceRequired = [
    {
      label: 'Interpretation',
      value: 'Interpretation'
    },
    {
      label: 'Translation',
      value: 'Translation'
    },
    {
      label: 'Transcription',
      value: 'Transcription'
    }
  ]
  return serviceRequired
})
.service('$custody', function () {
  let custody = [
    {
      label: 'Detained',
      value: 'DETAINED'
  },
  {
      label: 'Non-Detained',
      value: 'NON DETAINED'
  }
  ]
  return custody
})
.service('$distanceToCourt', function () {
  let distanceToCourt = [];
  let i = 1
  while (i <= 5) {
    distanceToCourt.push({
      label: `<= ${i * 50} Miles`,
      value: (i * 50).toString()
    })
    i++;
  }
  return distanceToCourt;
})
.service('$expirations', function () {
  let expirations = [
    {
      label: '1 Day',
      value: '1'
    },
    {
      label: '2 Days',
      value: '2'
    },
    {
      label: '3 Days',
      value: '3'
    },
    {
      label: '4 Days',
      value: '4'
    },
    {
      label: '5 Days',
      value: '5'
    },
    {
      label: '6 Days',
      value: '6'
    },
    {
      label: '1 Week',
      value: '7'
    },
    {
      label: '2 Weeks',
      value: '14'
    }
  ]
  return expirations;
})
.service('$rates', function () {
  let rates = [
    {
      label: '<= $20/hour',
      value: '20'
    },
    {
      label: '<= $30/hour',
      value: '30'
    },
    {
      label: '<= $40/hour',
      value: '40'
    },
    {
      label: '<= $50/hour',
      value: '50'
    },
    {
      label: '<= $60/hour',
      value: '60'
    },
    {
      label: '<= $70/hour',
      value: '70'
    },
    {
      label: '<= $80/hour',
      value: '80'
    },
    {
      label: '<= $90/hour',
      value: '90'
    },
    {
      label: '<= $100/hour',
      value: '100'
    }
  ]
  return rates;
})
.service('$experienceType', function () {
  let experienceType = [
    {
      label: 'Consecutive',
      value: 'Consecutive'
    },
    {
      label: 'Simultaneous',
      value: 'Simultaneous'
    },
    {
      label: 'Court',
      value: 'Court'
    },
    {
      label: 'EOIR',
      value: 'EOIR'
    },
  ]
  return experienceType;
})
.service('$experienceLevels', function () {
  let experienceLevels = [];
  let i = 1
  while (i <= 10) {
    experienceLevels.push({
      label: `>= ${i} year${i > 1 ? 's' : ''}`,
      value: (i).toString()
    })
    i++;
  }
  return experienceLevels;
})
.service('$scheduledTimes', function () {
  let scheduledTimes = [];
  let i = 8
  while (i <= 17) {
    scheduledTimes.push({
      label: `${i > 12 ? i-12: i} ${i >= 12 ? 'PM' : 'AM'}`,
      value: i
    })
    i++;
  }
  return scheduledTimes;
})
.service('$judgeRating', function () {
  let custody = [
    {
      label: '1 *',
      value: '1'
  },
  {
      label: '2 **',
      value: '2'
  },
  {
      label: '3 ***',
      value: '3'
  },
  {
      label: '4 ****',
      value: '4'
  },
  {
      label: '5 *****',
      value: '5'
  }
  ]
  return custody
})
.service('$years', function () {
  let experienceLevels = [];
  let i = 1
  while (i <= 10) {
    experienceLevels.push({
      label: `${i} year${i > 1 ? 's' : ''}`,
      value: (i).toString()
    })
    i++;
  }
  return experienceLevels;
})
.service('$attributes', function () {
  let attribute = [{
    label: 'Interpreter',
    value: 'Interpreter',
    child: [{
      label: 'Name',
      value: 'name'
    }, {
      label: 'Vendor Id',
      value: 'vendorId'
    }]
  }, {
    label: 'Work Order',
    value: 'Work Order',
    child: [{
      label: 'COI',
      value: 'coi'
    }, {
      label: 'Service Type',
      value: 'sertype'
    }]
  }, {
    label: 'Court',
    value: 'Court',
    child: [{
      label: 'Name',
      value: 'name'
    }, {
      label: 'Code',
      value: 'internalId'
    }]
  }, {
    label: 'Judge Info',
    value: 'Judge Info',
    child: [{
      label: 'Name',
      value: 'name'
    }]
  }, {
    label: 'Case Info',
    value: 'Case Info',
    child: [{
      label: 'Scheduled',
      value: 'sched'
    }]
  }, {
    label: 'Notes',
    value: 'Interpreter Instructions',
    child: [{
      label: 'Interpreter Instructions',
      value: 'interpreternotes'
    }]
  }, {
    label: 'Reporting Location',
    value: 'Reporting Location',
    child: [{
      label: 'Address',
      value: 'address'
    }]
  },
  {
    label: 'Alien Number',
    value: 'Alien Number',
    child: [{
      label: 'Alien Number',
      value: 'anumb'
    }]
  },
  {
    label: 'Language',
    value: 'Language',
    child: [{
      label: 'Language',
      value: 'langname'
    }]
  },
  {
    label: 'Nationality',
    value: 'Nationality',
    child: [{
      label: 'Nationality',
      value: 'natname'
    }]
  },
  {
    label: 'Custody',
    value: 'Custody',
    child: [{
      label: 'Custody',
      value: 'custody'
    }]
  },
  {
    label: 'Hearing Type',
    value: 'Hearing Type',
    child: [{
      label: 'Hearing Type',
      value: 'hearingtype'
    }]
  }];

// 'Reporting Location': {
//     address: `${coiDetails.address} ${coiDetails.city}, ${coiDetails.state} ${coiDetails.zip}`,
// },
// 'Alien Number': {anumb: coiDetails.anumb},
// 'Language': {langname: coiDetails.langname},
// 'Nationality': {natname: coiDetails.natname || null},
// 'Custody': {custody: coiDetails.custody || null},
// 'Hearing Type': {hearingtype: coiDetails.hearingtype || null},
  return attribute;
})
.service('$roleTypes', function () {
  let roleTypes =[{
    label: 'Internal Coordinator',
    value: 'Internal Coordinator'
  },{
    label: 'External Coordinator',
    value: 'External Coordinator'
  },{
    label: 'Interpreter',
    value: 'Interpreter'
  },{
    label: 'Judge',
    value: 'Judge'
  },{
    label: 'Internal Admin',
    value: 'Internal Admin'
  },{
    label: 'External Admin',
    value: 'External Admin'
  }];
  return roleTypes;
})
.service('$eventTypes', function () {
  let roleTypes =[{
    label: 'WO Event',
    value: 'WO Event'
  },{
    label: 'System Event',
    value: 'System Event'
  },{
    label: 'Service Portal Event',
    value: 'Service Portal Event'
  },
  {
    label: 'Service Event',
    value: 'Service Event'
  }];
  return roleTypes;
})
.service('$setMaskInAlien', function () {
  return function (record) {
    if (record && record.anumb) {
      var fullNumber = (record.anumb).toString();
      var last4Digits = fullNumber.slice(6);
      var maskedNumber = last4Digits.padStart(fullNumber.length, '*');
      return maskedNumber
    } else {
      return 'N/A';
    }
  }
})
.service('$getTZOffset', function ($timezones) {
  return function (value) {
    value = value == 'MST7' ? 'MST' : value == 'HAST10' ? 'HST' : value;
    var timeZone = _.find($timezones, function (timezone) {
      if (value === timezone.value) {
        return true;
      } else if (value === timezone.label) {
        return true;
      } else if (value === timezone.offset) {
        return true;
      }
      return false;
    });
    return timeZone;
  }
})
.service('$passwordStrengthValidator', function () {
  return function (value) {
    if (!value) {
      return null;
    }
    owaspPasswordStrengthTest.config({
      maxLength              : 20,
      minLength              : 8
    });
    var result = owaspPasswordStrengthTest.test(value);
    if(result && result.errors.length > 0){
      var errors = '';
      result.errors.forEach((error) => {
        errors += `- ${error}<br>`;
      })
      return errors;
    }
    return null;
  }
})
.service('$showError', function (ngToast) {
  return function (error, isSendMessage) {
    let errMessage = error.message || error;
    if (error && error.data && error.data.message) errMessage = error.data.message;
    if (error && error.data && error.data.reason) errMessage = error.data.reason;
    errMessage = errMessage && typeof errMessage == 'string' ? errMessage.replace('PreAuthentication failed with error', '') : errMessage;
    errMessage = errMessage && typeof errMessage == 'string' ? errMessage.replace('PostAuthentication failed with error', '') : errMessage;
    if (isSendMessage) {
      return errMessage
    } else {
      return ngToast.create({
        content: errMessage,
        className: 'danger'
      });
    }
  }
})
.service('$showSuccess', function (ngToast) {
  return function (message, isSendMessage) {
    message = message && typeof message == 'string' ? message.replace('PreAuthentication failed with error', '') : message;
    message = message && typeof message == 'string' ? message.replace('PostAuthentication failed with error', '') : message;
    if (isSendMessage) {
      return message
    } else {
      return ngToast.create({
        content: message,
        className: 'success'
      });
    }
  }
})
.config(function (IdleProvider, KeepaliveProvider, IDLE_SCREEN_CONFIG, idleTime, idleTimeout) {
  var idleScreenConfigStorage = localStorage.getItem(IDLE_SCREEN_CONFIG);
  var idleScreenConfig = JSON.parse(idleScreenConfigStorage);
  if (idleScreenConfig && idleScreenConfig.isEnabled) {
    IdleProvider.idle(idleScreenConfig.idleTimeInSeconds);
    // IdleProvider.timeout(5);
    // KeepaliveProvider.interval(10);
  }
  IdleProvider.idle(idleTime);
  IdleProvider.timeout(idleTimeout);
  // KeepaliveProvider.interval(5);
})
.run(function ($rootScope, Idle, $auth, $state, $sessionExpireCheck, $authStorage) {
  Idle.watch();
  $rootScope.$on('IdleStart', function () {
    // the user appears to have gone idle
    // console.log('--- IdleStart', new Date())
  });
  $rootScope.$on('IdleTimeout', function () {
    // end their session and redirect to login
    // console.log('Idle Timeout')
    // Logout
    // console.log('Logout Idle Watch', $sessionExpireCheck.isWarning)
    // const isServicePortalUser = localStorage.getItem('isServicePortalUser');
    // $auth.logout()
    //   .then(() => {
    //     if (isServicePortalUser && JSON.parse(isServicePortalUser)) {
    //       $state.go('app.account.services.signin');
    //     } else {
    //       $state.go('app.account.login');
    //     }
    //   })
    //   .catch((err) => {
    //     $authStorage.clearToken();
    //     let errMessage = err.message || err;
    //     if (err && err.data && err.data.message) errMessage = err.data.message;
    //     console.log('Idle', errMessage)
    //   })
    Idle.unwatch();
  });
  $rootScope.$on('IdleEnd', function () {
    // console.log('-- IdleEnd', new Date())
  });
  // $rootScope.$on('Keepalive', function () {
  //   // do something to keep the user's session alive
  //   console.log('Going to Keepalive', new Date())
  // });
})
.run(function ($log, $http, $rootScope, $state, $pageTitle, $metaInformation, $clientSwitcher, $activeClientSettings, $activeClient, Authorizor) {
  var firstRouteLoad = true;
  // ngMeta.init();
  $rootScope.glob = {
    clientSwitch: $clientSwitcher,
    pageTitle: $pageTitle,
    metaInfo:  $metaInformation
  };

  if (!$rootScope.isPageReload) {
    $rootScope.isPageReload = {
      socketCourtOps: true,
      socketWO: true,
    };
  }
  $activeClientSettings(function (client, settings) {
    if(!client) {
      $('body').css('background-color', '');
      return $log.warn('APP: No client selected.');
    }

    if(settings && settings.backgroundColor){
      $('body').css('background-color', settings.backgroundColor);
      // setTimeout(function(){
      //   $('.task-header p').css('background-color', settings.backgroundColor);
      // },2000);
    }

    if(!$rootScope.textColor) {
      $rootScope.textColor = settings.textColor || 'white';
    }
    // $log.debug('APP: Active client settings:', client.name, settings);
  });

  $rootScope.$on('$stateChangeStart', function (event, next, nextParams, from) {

    // used by state Authorizor
    $rootScope.nextState       = next;
    $rootScope.nextStateParams = nextParams;
    $rootScope._previousState = from.name || from;
    if(!firstRouteLoad) {
      Authorizor()
        .catch(function () {
          event.preventDefault();
        });
    }
    else {
      firstRouteLoad = false;
    }
  });

  $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
    if(error === 'no login') {

      // save state for later when logging in
      $rootScope.onLogonState       = toState;
      $rootScope.onLogonStateParams = toParams;
      const isServicePortalUser = localStorage.getItem('isServicePortalUser')
        if (isServicePortalUser && JSON.parse(isServicePortalUser)) {
          return $state.go('app.account.services');
        } else {
          return $state.go('app.account.login');
        }
    }
    else if(error === 'access denied') {
      return $state.go('app.error.accessdenied');
    }
    else if(error === 'dashboard home') {
      return $state.go('app.account.dashboard');
    }

    console.log('state change error', error);
  });
})
.controller('AccountLoginCtrl', AccountLoginCtrl)
.controller('SatisfactionContainerCtrl', SatisfactionContainerCtrl)
.controller('SatisfactionEntryCtrl', SatisfactionEntryCtrl)
.controller('DashboardSettingsManagersCreateCtrl', DashboardSettingsManagersCreateCtrl)
.controller('DashboardSettingsManagersIndexCtrl', DashboardSettingsManagersIndexCtrl)
.controller('DashboardSettingsManagersCtrl', DashboardSettingsManagersCtrl)
.controller('DashboardSettingsManagersUpdateCtrl', DashboardSettingsManagersUpdateCtrl)
.controller('DashboardSettingsSurveyScoreIndexCtrl', DashboardSettingsSurveyScoreIndexCtrl)
.controller('DashboardTasksWidgetCtrl', DashboardTasksWidgetCtrl)
.controller('AdminIndexCtrl', AdminIndexCtrl)
.controller('AdminUsersIndexCtrl', AdminUsersIndexCtrl)
.controller('printTaskCtrl1', printTaskCtrl1Ctrl)
.controller('SupportCtrl', SupportCtrl)
.controller('ServiceDetailCtrl', ServiceDetailCtrl)
.controller('AccountCtrl',AccountCtrl)
.controller('SuppressionListCtrl', SuppressionListCtrl)
.service('$earlyFINOrNot', $earlyFINOrNotService)
.service('$lateTime', $lateTimeService)
.service('$checkBusinessHours', $checkBusinessHoursService)
.service('$earlyReleaseFOUT', $earlyReleaseFOUTService)
.service('$getWOTaskCommon', $getWOTaskCommonService)
.service('$autoTimezoneFormat', $autoTimezoneFormatService)
.service('$getTZTimeList', $getTZTimeListService)
.service('$assessmentTypes', $assessmentTypesService)
.service('$assessmentParts', $assessmentPartsService)
.service('$degreeOrCertificateType', $degreeOrCertificateTypeService)
.service('$degreeList', $degreeListService)
.service('$assessmentYears', $assessmentYearsService)
.service('$layoutItems', $layoutItemsService)
.service('$printDate', $printDateService)
.service('$getImageFilePath', $getImageFilePathService)
.service('$officeOperationColumnFilter', $officeOperationColumnFilterService)
.service('$judgeInOutValue', $judgeInOutValueService)
.service('$stateFilter', $stateFilterService)
.service('$events', $eventsService)
.service('$sessionItems', $sessionItemsService)
.service('$permissionMatrixSourceType', $permissionMatrixSourceTypeService)
.service('$performTaskResolveHistory', $performTaskResolveHistoryService)
.service('$performTaskHistory', $performTaskHistoryService)
.service('$performTaskCreate', $performTaskCreateService)
.service('$performTaskResolve', $performTaskResolveService)
.service('$performTaskArchive', $performTaskArchiveService)
.service('$performTaskReject', $performTaskRejectService)
.service('$performTaskAssign', $performTaskAssignService)
.service('$performTaskReopen', $performTaskReopenService)
.service('$performTaskRemove', $performTaskRemoveService)
.service('$performTaskBucketSelect', $performTaskBucketSelectService)
.service('$performTaskUpdate', $performTaskUpdateService)
.service('$performForwardTaskEmail', $performForwardTaskEmailService)
.service('$performDueUpdate', $performDueUpdateService)
.service('$performToggleState', $performToggleStateService)
.service('$performSurveyTaskCreate', $performSurveyTaskCreateService)
.service('$performTaskTranscribeAudio', $performTaskTranscribeAudioService)
.service('$performTaskAudio', $performTaskAudioService)
.service('$performTaskScore', $performTaskScoreService)
.service('$convertSecondToTime', $convertSecondToTimeService)
.service('$getECOITimezoneOffset', $getECOITimezoneOffsetService)
.service('$woStatus', $woStatusService)
.service('$afterCognitoAuthLogin', $afterCognitoAuthLoginService)
.service('$serviceStatusWithBucketName', $serviceStatusWithBucketNameService)
.service('$servicePanelStatus', $servicePanelStatusService)
.service('$updateSCSSVariables', $updateSCSSVariablesService)
.service('$commonPrint', $commonPrintService)
.service('$commonPrint', $commonPrintService1)
.service('$awsService', $awsService)
.directive('connectionStatus', connectionStatusDirective)
.directive('assignmentStatusReport', assignmentStatusReportDirective)
.directive('mainCourtOperations', mainCourtOperationsDirective)
.directive('headerMenu', headerMenuDirective)
.directive('interpreterResource', interpreterResourceDirective)
.directive('communications', communicationsDirective)
.directive('contractDocs', contractDocsDirective)
.directive('payHistory', payHistoryDirective)
.directive('interpreterCourtDistance', interpreterCourtDistanceDirective)
.directive('interpreterDocument', interpreterDocumentDirective)
.directive('interpreterDqs', interpreterDqsDirective)
.directive('interpreterLanguages', interpreterLanguagesDirective)
.directive('rates', ratesDirective)
.directive('interpreterTravel', interpreterTravelDirective)
.directive('training', trainingDirective)
.directive('workHistory', workHistoryDirective)
.directive('piplineOperations', piplineOperationsDirective)
.directive('tooltipDirective', tooltipDirective)
.directive('recruiting', recruitingDirective)
.directive('resourceAnalytics', resourceAnalyticsDirective)
.directive('workflow', workflowDirective)
.directive('programSupport', programSupportDirective)
.directive('sosiOneReporting', sosiOneReportingDirective)
.directive('analytics', analyticsDirective)
// ..
.directive('liveReports', liveReportsDirective)
.directive('recruitingReport', recruitingReportDirective)
.directive('assignment', assignmentDirective)
.directive('autoAssignedCount', autoAssignedCountDirective)
.directive('autoAssignedReport', autoAssignedReportDirective)
.directive('coiTimeReport', coiTimeReportDirective)
.directive('interpreterAvailableWos', interpreterAvailableWosDirective)
.directive('interpreterNextDayScheduled', interpreterNextDayScheduledDirective)
.directive('interpreterScheduledNotification', interpreterScheduledNotificationDirective)
.directive('paymentHistory', paymentHistoryDirective)
.directive('travel', travelDirective)
// .directive('woForecasting', woForecastingDirective)
.directive('courttime', courttimeDirective)
.directive('woInvoices', woInvoicesDirective)
.directive('workOrders', workOrdersDirective)
.directive('satisfactionContainer', satisfactionContainerDirective)
.directive('satisfactionEntry', satisfactionEntryDirective)
.directive('clientDevice', clientDeviceDirective)
.directive('courtInterpreterDistance', courtInterpreterDistanceDirective)
.directive('pendingInterpreterDistances', pendingInterpreterDistancesDirective)
.directive('role', roleDirective)
.directive('screen', screenDirective)
.directive('user', userDirective)
.directive('auditLogs', auditLogsDirective)
.directive('clientRule', clientRuleDirective)
.directive('calendar', calendarDirective)
.directive('financial', financialDirective)
.directive('interpreters', interpretersDirective)
.directive('commentsTable', commentsTableDirective)
.directive('resolveCodesTable', resolveCodesTableDirective)
//...

.directive('scoresTable', scoresTableDirective)
.directive('pipelineOps', pipelineOpsDirective)
.directive('reports', reportsDirective)
.directive('requisitions', requisitionsDirective)
.directive('fileDropzone', fileDropzoneDirective)
.directive('taskMap', taskMapDirective)
.directive('dashboardTasks', dashboardTasksDirective)
.directive('leaveTable', leaveTableDirective)
.directive('productionTable', productionTableDirective)
.directive('travelTable', travelTableDirective)
.directive('workTable', workTableDirective)
.directive('invoicePanel', invoicePanelDirective)
.directive('notificationManagement', notificationManagementDirective)
.directive('userNotification', userNotificationDirective)
// .directive('taskStats', taskStatsDirective)
.factory('staticParams', staticParamsFactory)
.factory('plugins', pluginsFactory)
.factory('Task', TaskFactory)
.factory('Bucket', BucketFactory)
.factory('AllTasksBucket', AllTasksBucketFactory)
.factory('TaskBucketPending', TaskBucketPendingFactory)
.factory('TaskBucketResolved', TaskBucketResolvedFactory)
.factory('TaskBucketUnresolved', TaskBucketUnresolvedFactory)
.factory('TaskBucketArchived', TaskBucketArchivedFactory)
.factory('CalculateTime', CalculateTimeFactory)
.factory('CalculateWorkOrderTime', CalculateWorkOrderTimeFactory)
.factory('TaskBucket', TaskBucketFactory)
.factory('ChartData', ChartDataFactory)
.factory('AwsService', AwsService)
.filter('phoneNumber', phoneNumberFilter)
.filter('selectionAvailability', selectionAvailabilityFilter)
.filter('selection', selectionFilter)
.filter('labourCategory', labourCategoryFilter)
.filter('languageArrayFilter', languageArrayFilter)
// success
.filter('languageArrayFilterByPrint', languageArrayFilterByPrintFilter)
.filter('cstDateFilter', cstDateFilter)
.filter('setRuleValue', setRuleValueFilter)
.filter('inArray', inArrayFilter)
.filter('usPhoneNumber', usPhoneNumberFilter)
.filter('usersWithRole', usersWithRoleFilter)
.filter('usersWithStatus', usersWithStatusFilter)
.filter('sentimentFilter', sentimentFilter)
.filter('range', rangeFilter)
.filter('capitalize', capitalizeFilter)
.filter('till', tillFilter)
.filter('priorityHuman', priorityHumanFilter)
.filter('timezoneDate', timezoneDateFilter)
.filter('replaceWork', replaceWorkFilter)
.filter('humanizeString', humanizeStringFilter)
.filter('getFirstLatter', getFirstLatterFilter)
.filter('activity', activityFilter)
.config(AccountConfig)
.config(ClientConfig)
.config(ClientsConfig)
.config(DatabaseConfig)
.config(AdHocConfig)
.config(AnalysisConfig)
.config(AssignmentsConfig)
.config(ContactConfig)
.config(DashboardConfig)
.config(InterpreterResourcesConfig)
.config(KioskConfig)
.config(LiveReportsConfig)
.config(MainDashboardConfig)
.config(MissedAptsConfig)
.config(EmailConfig)
.config(NotificationLogsConfig)
.config(PushConfig)
.config(SmsConfig)
.config(OfficeInformationConfig)
.config(OfficeOperationsConfig)
.config(OnDemandContactConfig)
.config(OtcAnyWhereConfig)
.config(PatientContactConfig)
.config(ProbMissedAptsConfig)
.config(ProgramsConfig)
.config(RankingConfig)
.config(RemoteWorkConfig)
.config(ReportsConfig)
.config(SatisfactionConfig)
.config(ScheduleTasksConfig)
.config(BucketsConfig)
.config(CampaignsConfig)
.config(CustomersConfig)
.config(DevicesConfig)
.config(EmployeesConfig)
.config(EquipmentsConfig)
.config(InterpreterCourtDistancesConfig)
.config(InventorysConfig)
.config(JudgesConfig)
.config(LanguagesConfig)
.config(LogsConfig)
.config(MobileAppsConfig)
.config(NationalityConfig)
.config(NotificationsManagementConfig)
.config(OfferingManagementConfig)
.config(OfficesConfig)
.config(PermissionMatrixConfig)
.config(PermissionsConfig)
.config(Postgres_data_syncConfig)
.config(ProgramConfig)
.config(RegionsConfig)
.config(Report_bucketsConfig)
.config(ResolveCodeConfig)
.config(RolesConfig)
.config(RulesConfig)
.config(SettingsConfig)
.config(suppressionListConfig)
.config(Survey_scoreConfig)
.config(SystemConfig)
.config(TablesConfig)
.config(UsersSessionConfig)
.config(UsersConfig)
.config(SosEmailInformationConfig)
.config(SurveysConfig)
.config(TaskCreateRulesConfig)
.config(HistoryConfig)
.config(TasksConfig)
.config(TimeProductionReportingLogConfig)
.config(TimeProductionReportingConfig)
.config(TrainingConfig)
.config(UnconfirmedConfig)
.config(UploadInterpreterPicsConfig)
.config(WorkflowConfig)
.config(LoginConfig)
.config(BillingMethodConfig)
.config(InvoicesConfig)
.config(ProjectsConfig)
.config(InvoicesPanelConfig)
.config(ServicePanelConfig)
.config(ServicesConfig)
.config(SigninConfig)
.config(SignupConfig)
.config(SettingsConfig1)
.config(AdminConfig)
.config(ClientsConfig1)
.config(UsersConfig1)
.config(ErrorConfig)
.config(ExternalConfig)
.config(RejectConfig)
.config(ResolveConfig)
.config(MainConfig)
.config(DqsReportsConfig)
.config(FulfillmentOpsDashboardConfig)
.config(InterpreterMatrixConfig)
.config(AssignmentReportConfig)
.config(DailyReportConfig)
.config(AssignmentStatusReportConfig)
.config(CotiConfig)
.config(CoviConfig)
.config(EcoiConfig)
.config(ElogConfig)
.config(PrintConfig)
.config(ResourceOpsDashboardConfig)
.config(InvoicePrintConfig)
.config(TaskOrgPerformanceConfig)
.config(TaskConfig)
.config(TimeReportingConfig)
.config(SupportConfig)
.controller('NavbarCtrl', NavbarCtrl)
.controller('DraggableCtrl', DraggableCtrl)
.controller('UasUsersCtrl', UasUsersCtrl)
.controller('UasExternalUsersCtrl', UasExternalUsersCtrl)
.controller('UasTaskCtrl', UasTaskCtrl)
.controller('UasModelInternalCtrl', UasModelInternalCtrl)
.controller('UasModelExternalCtrl', UasModelExternalCtrl)
.controller('UasCtrl', UasCtrl)
.service('$clientUserSetting', $clientUserSettingService)
.service('$client', $clientService)
.service('$clientSwitcherStorage', $clientSwitcherStorageService)
.service('$activeClient', $activeClientService)
.service('$clientSwitcher', $clientSwitcherService)
.service('$attachment', $attachmentService)
.service('$downloadAttachment', $downloadAttachmentService)
.service('$autoAssigned', $autoAssignedService)
.service('$clientBucket', $clientBucketService)
.service('$clientCampaign', $clientCampaignService)
.service('$cases', $casesService)
.service('$cognitoUserPool', $cognitoUserPoolService)
.service('$setGetCognitoUser', $setGetCognitoUserService)
.service('$cognitoUser', $cognitoUserService)
.service('$cognito', $cognitoService)
.service('$clientCOINotes', $clientCOINotesService)
.service('$clientCoiVRIInfo', $clientCoiVRIInfoService)
.service('$clientContactCoordinate', $clientContactCoordinateService)
.service('$clientEmployee', $clientEmployeeService)
.service('$coordinators', $coordinatorsService)
.service('$courtOperations', $courtOperationsService)
.service('$clientCustomer', $clientCustomerService)
.service('$clientDailyWoReports', $clientDailyWoReportsService)
.service('$devicesManager', $devicesManagerService)
.service('$clientDivision', $clientDivisionService)
.service('$dqsReports', $dqsReportsService)
.service('$clientEquipment', $clientEquipmentService)
.service('$clientDeviceEventsManager', $clientDeviceEventsManagerService)
.service('$clientExternalUsers', $clientExternalUsersService)
.service('$externalTask', $externalTaskService)
.service('$clientFeature', $clientFeatureService)
.service('$fundingEvents', $fundingEventsService)
.service('$mobileApp', $mobileAppService)
.service('$clientHolidays', $clientHolidaysService)
.service('$icDegreesOrCertificates', $icDegreesOrCertificatesService)
.service('$icaTracking', $icaTrackingService)
.service('$interpreterMatrix', $interpreterMatrixService)
.service('$interpreterAssessments', $interpreterAssessmentsService)
.service('$interpretersWOAvailability', $interpretersWOAvailabilityService)
.service('$clientInterpreterCourtDistance', $clientInterpreterCourtDistanceService)
.service('$interpreterDocument', $interpreterDocumentService)
.service('$clientInterpreterProfile', $clientInterpreterProfileService)
.service('$clientInterpreterResources', $clientInterpreterResourcesService)
.service('$interpreterScheduledNT', $interpreterScheduledNTService)
.service('$clientInterpreter', $clientInterpreterService)
.service('$intLangs', $intLangsService)
.service('$clientInventory', $clientInventoryService)
.service('$invoice', $invoiceService)
.service('$syncHistory', $syncHistoryService)
.service('$judges', $judgesService)
.service('$clientKnowledgeLibrary', $clientKnowledgeLibraryService)
.service('$clientLanguages', $clientLanguagesService)
.service('$languageTypes', $languageTypesService)

.service('$Linguist', $LinguistService)
.service('$lists', $listsService)
.service('$liveReports', $liveReportsService)
.service('$clientManager', $clientManagerService)
.service('$mobileAppManager', $mobileAppManagerService)
.service('$monthlyUserAccessLogs', $monthlyUserAccessLogsService)
.service('$nationalities', $nationalitiesService)
.service('$clientNotificationLogs', $clientNotificationLogsService)
.service('$notificationManagement', $notificationManagementService)
.service('$clientUserNotifications', $clientUserNotificationsService)
.service('$offeringRules', $offeringRulesService)
.service('$clientOfficeInformation', $clientOfficeInformationService)
.service('$officeOperations', $officeOperationsService)
.service('$printCoiCoti', $printCoiCotiService)
.service('$clientOffice', $clientOfficeService)
.service('$openWorkOrders', $openWorkOrdersService)
.service('$OtcAnyWhere', $OtcAnyWhereService)
.service('$patientContacts', $patientContactsService)
.service('$permissionMatrix', $permissionMatrixService)
.service('$clientPermissions', $clientPermissionsService)
.service('$clientPermissionTypes', $clientPermissionTypesService)
.service('$clientPGQueryBuilder', $clientPGQueryBuilderService)
.service('$pipelineOperations', $pipelineOperationsService)
.service('$programs', $programsService)
.service('$clientQueryBuilder', $clientQueryBuilderService)
.service('$clientRegion', $clientRegionService)
.service('$clientReportBucketsUpload', $clientReportBucketsUploadService)
.service('$clientReportBuckets', $clientReportBucketsService)
.service('$taskReports', $taskReportsService)
.service('$clientRequestInterpreter', $clientRequestInterpreterService)
.service('$clientResolveCodes', $clientResolveCodesService)
.service('$clientRoles', $clientRolesService)
.service('$rulesExecutionLogsManager', $rulesExecutionLogsManagerService)
.service('$rulesLogsManager', $rulesLogsManagerService)
.service('$rulesManager', $rulesManagerService)
.service('$scheduleTasksManager', $scheduleTasksManagerService)
.service('$ServiceProjectComment', $ServiceProjectCommentService)
.service('$ServiceUserProject', $ServiceUserProjectService)
.service('$serviceComments', $serviceCommentsService)
.service('$servicePanel', $servicePanelService)
.service('$ServicePortal', $ServicePortalService)
.service('$serviceJobInvoices', $serviceJobInvoicesService)
.service('$clientSetting', $clientSettingService)
.service('$activeClientSettings', $activeClientSettingsService)
.service('$sosEmailNotification', $sosEmailNotificationService)
.service('$sosnots', $sosnotsService)
.service('$clientSubClient', $clientSubClientService)
.service('$clientSurveyQuestion', $clientSurveyQuestionService)
.service('$serveySentiment', $serveySentimentService)
.service('$clientSurvey', $clientSurveyService)
.service('$clientSystemLogs', $clientSystemLogsService)
.service('$tablesManager', $tablesManagerService)
.service('$taskCreateRules', $taskCreateRulesService)
.service('$taskActionsLogsManager', $taskActionsLogsManagerService)
.service('$clientTask', $clientTaskService)
.service('$clientTimeProductionReporting', $clientTimeProductionReportingService)
.service('$clientTrainingManager', $clientTrainingManagerService)
.service('$clientUpload', $clientUploadService)
.service('$uploadS3Attachment', $uploadS3AttachmentService)
.service('$userNotification', $userNotificationService)
.service('$usersSession', $usersSessionService)
.service('$clientUser', $clientUserService)
.service('$WOForecasting', $WOForecastingService)
.service('$database', $databaseService)
.service('$forgotPassword', $forgotPasswordService)
.service('$user', $userService)
.service('$authStorage', $authStorageService)
.service('$auth', $authService)
.service('$sessionExpireCheck', $sessionExpireCheckService)
.service('$states', $statesService)
.service('$defaultDateRangFilter', $defaultDateRangFilterService)
.service('$colorUtil', $colorUtilService)
.service('$convertDate', $convertDateService)
.service('$inherit', $inheritService)
.service('$metaInformation', $metaInformationService)
.service('$pageTitle', $pageTitleService)
.service('$randomly', $randomlyService)
.service('$string', $stringService)
.service('$dateValue', $dateValueService)
.directive('audioPlayer', audioPlayerDirective)
.directive('audioPlayerControls', audioPlayerControlsDirective)
.directive('audioDuration', audioDurationDirective)
.directive('bucketActions', bucketActionsDirective)
.directive('employeeTaskPerformance', employeeTaskPerformanceDirective)
.directive('featureTabs', featureTabsDirective)
.directive('formBucket', formBucketDirective)
.directive('formCampaign', formCampaignDirective)
.directive('formCaptioning', formCaptioningDirective)
.directive('onFinishRender', onFinishRenderDirective)
.directive('createRule', createRuleDirective)
.directive('formCustomer', formCustomerDirective)
.directive('formDivision', formDivisionDirective)
.directive('formEmployee', formEmployeeDirective)
.directive('formEquipment', formEquipmentDirective)
.directive('formInventory', formInventoryDirective)
.directive('formJudge', formJudgeDirective)
.directive('formLanguages', formLanguagesDirective)
.directive('formLinguistUser', formLinguistUserDirective)
.directive('formLocalization', formLocalizationDirective)
.directive('formManagerEmployees', formManagerEmployeesDirective)
.directive('formManager', formManagerDirective)
.directive('formMobileApp', formMobileAppDirective)
.directive('formNationality', formNationalityDirective)
.directive('offeringRule', offeringRuleDirective)
.directive('multiSelect', multiSelectDirective)
.directive('singleSelect', singleSelectDirective)
// .directive('formOffice', formOfficeDirective)
.directive('formOnSiteInterpretation', formOnSiteInterpretationDirective)
.directive('formPermissions', formPermissionsDirective)
.directive('program', programDirective)
.directive('formRegion', formRegionDirective)
.directive('formReportBucket', formReportBucketDirective)
.directive('formResolveCode', formResolveCodeDirective)
.directive('formRoles', formRolesDirective)
.directive('scheduleTask', scheduleTaskDirective)
.directive('formServiceUser', formServiceUserDirective)
.directive('starRating', starRatingDirective)
.directive('formSubClient', formSubClientDirective)
.directive('sentiment', sentimentDirective)
.directive('surveyQuestion', surveyQuestionDirective)
.directive('formSurveyTask', formSurveyTaskDirective)
.directive('clientTable', clientTableDirective)
.directive('taskCreateRule', taskCreateRuleDirective)
.directive('formTask', formTaskDirective)
.directive('formTelephonicInterpretation', formTelephonicInterpretationDirective)
.directive('formTranscription', formTranscriptionDirective)
.directive('projectHistory', projectHistoryDirective)
.directive('serviceDetailView', serviceDetailViewDirective)
.directive('formTranslation', formTranslationDirective)
.directive('formUser', formUserDirective)
.directive('gridCell', gridCellDirective)
.directive('grid', gridDirective)
.directive('mongooseError', mongooseErrorDirective)
.directive('navbarItem', navbarItemDirective)
.directive('ngDropdownMultiselect', ngDropdownMultiselectDirective)
.directive('notificationSettings', notificationSettingsDirective)
.directive('searchSelectPagination', searchSelectPaginationDirective)
.directive('selectAndSearch', selectAndSearchDirective)
.directive('showAttachments', showAttachmentsDirective)
.directive('taskCalendar', taskCalendarDirective)
.directive('surveyTaskControls', surveyTaskControlsDirective)
.directive('taskControls', taskControlsDirective)
.directive('myToggleSwitch', myToggleSwitchDirective)
.directive('uploadFormS3', uploadFormS3Directive)
.directive('uploadForm', uploadFormDirective)
.directive('draggable', draggableDirective)
.directive('draggableTrigger', draggableTriggerDirective)
.directive('formErrors', formErrorsDirective)
.directive('formErrorMessages', formErrorMessagesDirective)
.directive('formErrorInput', formErrorInputDirective)
.directive('loadSpinner', loadSpinnerDirective)
.directive('mustMatch', mustMatchDirective)
.directive('numericonly', numericonlyDirective)
.directive('presetTime', presetTimeDirective)
.directive('ruleWindow', ruleWindowDirective)
.directive('uasUsers', uasUsersDirective)
.directive('uasExternalUsers', uasExternalUsersDirective)
.directive('uasTask', uasTaskDirective)
.directive('uasModelInternal', uasModelInternalDirective)
.directive('uasModelExternal', uasModelExternalDirective)
.directive('userAssignSelector', userAssignSelectorDirective)
.directive('validateDate', validateDateDirective)
.directive('elisaPipelineCounts', elisaPipelineCountsDirective)
.factory('ClientUserSetting', ClientUserSettingFactory)
.factory('Client', ClientFactory)
.factory('AutoAssigned', AutoAssignedFactory)
.factory('ClientBucket', ClientBucketFactory)
.factory('ClientCampaign', ClientCampaignFactory)
.factory('Cases', CasesFactory)
.factory('ClientCOINotes', ClientCOINotesFactory)
.factory('ClientCoiVRIInfo', ClientCoiVRIInfoFactory)
.factory('clientContactCoordinate', clientContactCoordinateFactory)
.factory('ClientContact', ClientContactFactory)
.factory('ClientEmployee', ClientEmployeeFactory)
.factory('Coordinators', CoordinatorsFactory)
.factory('CourtOperations', CourtOperationsFactory)
.factory('ClientCustomer', ClientCustomerFactory)
.factory('ClientDailyWoReports', ClientDailyWoReportsFactory)
.factory('DevicesManager', DevicesManagerFactory)
.factory('ClientDivision', ClientDivisionFactory)
.factory('DQSReports', DQSReportsFactory)
.factory('ClientEquipment', ClientEquipmentFactory)
.factory('ClientDeviceEventsManager', ClientDeviceEventsManagerFactory)
.factory('ClientExternalUsers', ClientExternalUsersFactory)
.factory('ExternalTask', ExternalTaskFactory)
.factory('ClientFeature', ClientFeatureFactory)
.factory('FunddingEvents', FunddingEventsFactory)
.factory('MobileApp', MobileAppFactory)
.factory('ClientHolidays', ClientHolidaysFactory)
.factory('IcDegreesOrCertificates', IcDegreesOrCertificatesFactory)
.factory('icaTracking', icaTrackingFactory)
.factory('InterpreterMatrix', InterpreterMatrixFactory)
.factory('InterpreterAssessments', InterpreterAssessmentsFactory)
.factory('InterpretersWOAvailability', InterpretersWOAvailabilityFactory)
.factory('ClientInterpreterCourtDistance', ClientInterpreterCourtDistanceFactory)
.factory('interpreterDocument', interpreterDocumentFactory)
.factory('ClientInterpreterProfile', ClientInterpreterProfileFactory)
.factory('ClientInterpreterResources', ClientInterpreterResourcesFactory)
.factory('InterpreterScheduledNT', InterpreterScheduledNTFactory)
.factory('ClientInterpreter', ClientInterpreterFactory)
.factory('IntLangs', IntLangsFactory)
.factory('ClientInventory', ClientInventoryFactory)
.factory('Invoice', InvoiceFactory)
.factory('SyncHistory', SyncHistoryFactory)
.factory('Judges', JudgesFactory)
.factory('ClientKnowledgeLibrary', ClientKnowledgeLibraryFactory)
.factory('ClientLanguages', ClientLanguagesFactory)
.factory('Linguist', LinguistFactory)
.factory('Lists', ListsFactory)
.factory('LiveReports', LiveReportsFactory)
.factory('ClientManager', ClientManagerFactory)
.factory('MobileAppManager', MobileAppManagerFactory)
.factory('MonthlyUserAccessLogs', MonthlyUserAccessLogsFactory)
.factory('Nationalities', NationalitiesFactory)
.factory('ClientNotificationLogs', ClientNotificationLogsFactory)
.factory('NotificationManagement', NotificationManagementFactory)
.factory('ClientUserNotifications', ClientUserNotificationsFactory)
.factory('OfferingRules', OfferingRulesFactory)
.factory('ClientOfficeInformation', ClientOfficeInformationFactory)
.factory('OfficeOperations', OfficeOperationsFactory)
.factory('ClientOffice', ClientOfficeFactory)
.factory('OpenWorkOrders', OpenWorkOrdersFactory)
.factory('OtcAnyWhere', OtcAnyWhereFactory)
.factory('PatientContacts', PatientContactsFactory)
.factory('PermissionMatrix', PermissionMatrixFactory)
.factory('ClientPermissions', ClientPermissionsFactory)
.factory('ClientPGQueryBuilder', ClientPGQueryBuilderFactory)
.factory('PipelineOperations', PipelineOperationsFactory)
.factory('Programs', ProgramsFactory)
.factory('ClientQueryBuilder', ClientQueryBuilderFactory)
.factory('ClientRegion', ClientRegionFactory)
.factory('ClientReportBucketsUpload', ClientReportBucketsUploadFactory)
.factory('ClientReportBuckets', ClientReportBucketsFactory)
.factory('TaskReportsManager', TaskReportsManagerFactory)
.factory('ClientRequestInterpreter', ClientRequestInterpreterFactory)
.factory('ClientResolveCodes', ClientResolveCodesFactory)
.factory('ClientRoles', ClientRolesFactory)
.factory('RulesExecutionLogsManager', RulesExecutionLogsManagerFactory)
.factory('RulesLogsManager', RulesLogsManagerFactory)
.factory('RulesManager', RulesManagerFactory)
.factory('ScheduleTasksManager', ScheduleTasksManagerFactory)
.factory('ServiceProjectComment', ServiceProjectCommentFactory)
.factory('ServiceUserProject', ServiceUserProjectFactory)
.factory('serviceComments', serviceCommentsFactory)
.factory('servicePanel', servicePanelFactory)
.factory('ServicePortal', ServicePortalFactory)
.factory('serviceJobInvoices', serviceJobInvoicesFactory)
.factory('ClientSetting', ClientSettingFactory)
.factory('SOSEmailNotification', SOSEmailNotificationFactory)
.factory('Sosnots', SosnotsFactory)
.factory('ClientSubClient', ClientSubClientFactory)
.factory('ClientSurveyQuestion', ClientSurveyQuestionFactory)
.factory('SurveySentiment', SurveySentimentFactory)
.factory('ClientSurvey', ClientSurveyFactory)
.factory('ClientSystemLogs', ClientSystemLogsFactory)
.factory('ClientTable', ClientTableFactory)
.factory('TaskCreateRules', TaskCreateRulesFactory)
.factory('TaskActionsLogsManager', TaskActionsLogsManagerFactory)
.factory('ClientTask', ClientTaskFactory)
.factory('ClientTimeProductionReporting', ClientTimeProductionReportingFactory)
.factory('ClientTrainingManager', ClientTrainingManagerFactory)
.factory('ClientUpload', ClientUploadFactory)
.factory('UserNotification', UserNotificationFactory)
.factory('UsersSession', UsersSessionFactory)
.factory('ClientUser', ClientUserFactory)
.factory('WOForecasting', WOForecastingFactory)
.factory('Database', DatabaseFactory)
.factory('ForgotPassword', ForgotPasswordFactory)
.factory('User', UserFactory)
.factory('AudioTrack', AudioTrackFactory)
.factory('AudioPlayList', AudioPlayListFactory)
.factory('Authorizor', AuthorizorFactory)
.factory('CollectionRecord', CollectionRecordFactory)
.factory('CollectionColumn', CollectionColumnFactory)
.factory('Collection', CollectionFactory)
.factory('Modal', ModalFactory)
.factory('SurveyModal', SurveyModalFactory)
.factory('LoadSpinnerCtrl', LoadSpinnerCtrlFactory)
.filter('fileSize', fileSizeFilter)
.filter('durationMS', durationMSFilter)
.filter('percentage', percentageFilter)
.filter('numberFormat', numberFormatFilter)
.filter('myTimeFilter', myTimeFilter)
.filter('yesNo', yesNoFilter)
.controller('AccountClientsCtrl', AccountClientsCtrl)
.controller('DashboardSettingsBucketsIndexCtrl', DashboardSettingsBucketsIndexCtrl)
.controller('DashboardSettingsCampaignsIndexCtrl', DashboardSettingsCampaignsIndexCtrl)
.controller('DashboardSettingsCustomersIndexCtrl', DashboardSettingsCustomersIndexCtrl)
.controller('DashboardSettingsDevicesIndexCtrl', DashboardSettingsDevicesIndexCtrl)
.controller('DashboardSettingsEmployeesIndexCtrl', DashboardSettingsEmployeesIndexCtrl)
.controller('DashboardSettingsEquipmentsIndexCtrl', DashboardSettingsEquipmentsIndexCtrl)
.controller('DashboardSettingsInventorysIndexCtrl', DashboardSettingsInventorysIndexCtrl)
.controller('DashboardSettingsJudgesIndexCtrl', DashboardSettingsJudgesIndexCtrl)
.controller('DashboardSettingsLanguagesIndexCtrl', DashboardSettingsLanguagesIndexCtrl)
.controller('DashboardSettingsLogsIndexCtrl', DashboardSettingsLogsIndexCtrl)
.controller('DashboardSettingsManagersIndexCtrl', DashboardSettingsManagersIndexCtrl)
.controller('DashboardSettingsMobileAppsIndexCtrl', DashboardSettingsMobileAppsIndexCtrl)
.controller('DashboardSettingsNationalityIndexCtrl', DashboardSettingsNationalityIndexCtrl)
.controller('DashboardSettingsNotificationManageIndexCtrl', DashboardSettingsNotificationManageIndexCtrl)
.controller('DashboardSettingsOffManageIndexCtrl', DashboardSettingsOffManageIndexCtrl)
.controller('DashboardSettingsOfficesIndexCtrl', DashboardSettingsOfficesIndexCtrl)
.controller('DashboardSettingsPermissionsIndexCtrl', DashboardSettingsPermissionsIndexCtrl)
.controller('PostgresDataSyncCasesCtrl', PostgresDataSyncCasesCtrl)
.controller('DashboardSettingsProgramIndexCtrl', DashboardSettingsProgramIndexCtrl)
.controller('DashboardSettingsRegionsIndexCtrl', DashboardSettingsRegionsIndexCtrl)
.controller('DashboardSettingsReportBucketsIndexCtrl', DashboardSettingsReportBucketsIndexCtrl)
.controller('DashboardSettingsResolveCodesIndexCtrl', DashboardSettingsResolveCodesIndexCtrl)
.controller('DashboardSettingsRolesIndexCtrl', DashboardSettingsRolesIndexCtrl)
.controller('DashboardSettingsRulesIndexCtrl', DashboardSettingsRulesIndexCtrl)
.controller('DashboardSettingsIndexCtrl', DashboardSettingsIndexCtrl)
.controller('DashboardSettingsSurveyScoreIndexCtrl', DashboardSettingsSurveyScoreIndexCtrl)
.controller('DashboardSettingsTablesIndexCtrl', DashboardSettingsTablesIndexCtrl)
.controller('DashboardSettingsUsersIndexCtrl', DashboardSettingsUsersIndexCtrl)
.controller('ServicePanelIndexCtrl', ServicePanelIndexCtrl)
.controller('AdminIndexCtrl', AdminIndexCtrl)
.controller('AdminUsersIndexCtrl', AdminUsersIndexCtrl)
.controller('NavbarCtrl', NavbarCtrl);

