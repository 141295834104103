const configENV = angular.module('configENV', [])
  .constant('appConfig', {
  "apiUrl": "https://dev.sosi1.com",
  "baseUrl": "/",
  "enableDebug": false,
  "UserPoolId": "us-east-1_ARbfIRxPd",
  "ClientId": "7qd39plmm2v37b5os0ehh2ee7r",
  "logoutApiUrl": "https://wygjbndq58.execute-api.us-east-1.amazonaws.com/dev"
})
  .constant('envConfig', {
  "env": "production"
});

export default configENV;
